import React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerShareButton, FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon, LinkedinIcon} from 'react-share'
import { ShareButtonsContainer, ShareButtonContainer } from './ShareButtons.style'
import firebase from 'gatsby-plugin-firebase'

const SIZE = 50

const getUrl = (url, source, addUtmSource) => {
  let useUrl = url
  if (url instanceof Function) {
    useUrl = url(source)
  }

  if (!addUtmSource) {
    return useUrl
  }
  if (useUrl.indexOf("?") > -1) {
    return `${useUrl}&utm_source=${source}`
  } 
  return `${useUrl}?utm_source=${source}`
} 

function ShareButtons({ url, text, title, addUtmSource, eventPrefix }) {
  const sendEvents = (eventName) => (...args) => {
    firebase
      .analytics()
      .logEvent(eventName, {
        url,
        text,
        title
      })
  }

  return <ShareButtonsContainer width={(SIZE + 7) * 5}>
    <ShareButtonContainer><FacebookShareButton beforeOnClick={sendEvents(`${eventPrefix}_FACEBOOK`)} url={getUrl(url, "facebook", addUtmSource)} quote={text}><FacebookIcon size={SIZE} round /></FacebookShareButton></ShareButtonContainer>
    <ShareButtonContainer><TwitterShareButton beforeOnClick={sendEvents(`${eventPrefix}_TWITTER`)} url={getUrl(url, "twitter", addUtmSource)} title={text}><TwitterIcon size={SIZE} round /></TwitterShareButton></ShareButtonContainer>
    <ShareButtonContainer><WhatsappShareButton beforeOnClick={sendEvents(`${eventPrefix}_WHATSAPP`)} separator=" " url={getUrl(url, "whatsapp", addUtmSource)} title={text}><WhatsappIcon size={SIZE} round /></WhatsappShareButton></ShareButtonContainer>
    <ShareButtonContainer><LinkedinShareButton beforeOnClick={sendEvents(`${eventPrefix}_LINKED_IN`)} url={getUrl(url, "linkedin", addUtmSource)} title={title} summary={text}><LinkedinIcon size={SIZE} round /></LinkedinShareButton></ShareButtonContainer>
    <ShareButtonContainer><EmailShareButton beforeOnClick={sendEvents(`${eventPrefix}_EMAIL`)} url={getUrl(url, "email", addUtmSource)} separator=" " subject={title} body={text}><EmailIcon size={SIZE} round /></EmailShareButton></ShareButtonContainer>
  </ShareButtonsContainer>
}

export default ShareButtons

import React from "react";
import { closeCircle } from "../../../images";
import {
  WizardModalContentsContainer,
  WizardModalContentsHeader,
  CloseButton,
  WizardModalContentsBodyContainer,
  WizardModalContentsBody,
  WizardModalContentsFooter,
} from "./Wizard.style";
import ChatFlow from "../../generic/ChatFlow";
import Text from "../../generic/Text";

function MokuTitle({ title, title2, title3, chatState }) {
  return (
    <div style={{ width: "100%", textAlign: "center", marginBottom: "28px" }}>
      {title && (
        <Text size={13} style={{ paddingLeft: "14px", paddingRight: "14px" }}>
          {title instanceof Function ? title(chatState) : title}
        </Text>
      )}
      {title2 && (
        <>
          {title && <div style={{ height: "8px" }} />}
          <Text size={26} bold style={{ paddingLeft: "14px", paddingRight: "14px" }}>
            {title2 instanceof Function ? title2(chatState) : title2}
          </Text>
        </>
      )}
      {title3 && (
        <>
          {(title2 || (title && !title2)) && <p />}
          <Text size={16} style={{ padding: "14px" }}>
            {title3 instanceof Function ? title3(chatState) : title3}
          </Text>
        </>
      )}
    </div>
  );
}

const createSteps = (steps) => {
  steps.forEach((step) => {
    if (!step.text && (step.title || step.title2 || step.title3)) {
      step.text = (chatState) => (
        <MokuTitle title={step.title} title2={step.title2} title3={step.title3} chatState={chatState} />
      );
    }
  });
  return steps;
};

function Wizard({
  name,
  open,
  setOpen,
  steps,
  fields,
  loading,
  initialValues,
  renderBodyHeader,
  renderBodyFooter,
  renderContentContainer,
  renderContentsBodyContainer,
  renderContentsBody,
  noCloseButton,
  startWithCleanState = true,
  ...props
}) {
  const BodyHeader = renderBodyHeader || (() => <div />);
  const BodyFooter = renderBodyFooter || (() => <div />);
  const ContentContainer = renderContentContainer || WizardModalContentsContainer;
  const ContentsBodyContainer = renderContentsBodyContainer || WizardModalContentsBodyContainer
  const ContentsBody = renderContentsBody || WizardModalContentsBody

  return (
    <ContentContainer>
      <WizardModalContentsHeader>
        {!noCloseButton && <CloseButton src={closeCircle} alt="X" onClick={() => setOpen(false)} />}
        <BodyHeader />
      </WizardModalContentsHeader>
      <ContentsBodyContainer>
        <ContentsBody>
          {open && !loading && (
            <ChatFlow
              name={name}
              steps={createSteps(steps)}
              fields={fields}
              startWithCleanState={startWithCleanState}
              initialValues={initialValues}
              onlyShowCurrent
              {...props}
            />
          )}
        </ContentsBody>
      </ContentsBodyContainer>
      <WizardModalContentsFooter>
        <BodyFooter />
      </WizardModalContentsFooter>
    </ContentContainer>
  );
}

export default Wizard;

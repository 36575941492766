import styled from '@emotion/styled'
import { Row, Col } from '../../generic/grid'
import { device } from '../../../theme.style' 

const RegisterProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 0
  }
`

const LogoImageContainer = styled.div`
  margin-top: 66px;
  margin-bottom: 52px;
  width: 118px;
`

const RegisterProfileContentContainer = styled(Col)`
  margin-top: 44px;
  padding-top: 8px;
`

const MobileBackground = styled.img`
  display: none;

  ${device.mobileL`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100wh;
    height: 100vh;
    z-index: -1;
  `}
`

export {
  RegisterProfileContainer,
  LogoImageContainer,
  RegisterProfileContentContainer,
  MobileBackground
}
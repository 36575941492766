import styled from '@emotion/styled'

const MokuTipModalContainer = styled.div`
  background-color: white;
  position: relative;
  width: 300px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 532px;
  height: 100vh;
  overflow: hidden;
`

const MokuTipModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 96px;
  padding-bottom: 35px;
  z-index: 2;
`

const MokuTipCard = styled.div`
  margin-top: -9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 240px;
  padding: 15px;
  padding-top: 35px;
  padding-bottom: 28px;
  background-color: ${props => `${props.theme.colors.white}66`};
  border-radius: 8px;
`

const CloseButton = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
`

const MokuTipModalActionContainer = styled.div`
  position: absolute;
  bottom: 35px
`

const BlobBackground = styled.img`
  position: absolute;
  top: -50px;
  width: 310px;
`

export {
  MokuTipModalContainer,
  MokuTipModalContentContainer,
  MokuTipCard,
  MokuTipModalActionContainer,
  CloseButton,
  BlobBackground
}

import React from 'react'
import { idTypes } from '../../constants'
import { isRequired, isValidIdNumber } from '../../lib/validators'
import { WizardModal } from '../shared'
import Auth from '../Auth'
// import { printCurrency } from '../../../lib/utils'
// import {} from './ForgottenAuth.style'
import gql from 'graphql-tag'

const HAS_EXISTING_USER = gql`query ($idNumber: String!, $idType: String) {
  hasExistingUser(idNumber: $idNumber, idType: $idType)
}`

const buildSteps = (setOpen) => ([{
  name: "start",
  nextAfterDelay: 1,
  nextSteps: ["step1"],
  noNext: true
}, {
  name: "step1",
  title: "Help me sign in",
  title2: "We need some info to access your registration details",
  fieldNames: ["idType", "idNumber"],
  nextSteps: ["findIdNumber"],
  nextTitle: "Submit"
}, {
  name: "findIdNumber",
  beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
    const { idType, idNumber } = chatState
    const { data } = await apolloClient.query({ query: HAS_EXISTING_USER, variables: { idType, idNumber } })
    changeFieldValue('authProvider', data.hasExistingUser)
  },
  title: "Help me sign in",
  title2: ({ authProvider }) => authProvider ? "Here we go!" : "We could not find a registered user with your ID number",
  nextAfterDelay: 1,
  excludeFromBack: true,
  nextSteps: ({ authProvider }) => authProvider ? ["found"] : ["notFound"],
  noNext: true
}, {
  name: "found",
  title: "Help me sign in",
  title2: "Here we go!",
  component: ({ chatState }) => <Auth authProvider={chatState.authProvider} noLogo />,
  showBack: true,
  noNext: true
},  {
  name: "notFound",
  title: "Help me sign in",
  title2: "We could not find a registered user with your ID number",
  component: () => <Auth noLogo login noForgotten />,
  showBack: true,
  noNext: true
}])

const fields = {
  idType: {
    label: "ID Type",
    type: "select",
    options: idTypes
  },
  idNumber: {
    label: "ID Number",
    validate: [isValidIdNumber, isRequired]
  }
}

function ForgottenAuth({ open, setOpen }) {
  return <WizardModal
    name="forgottenAuth"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    initialValues={{ idType: "rsa_id" }}
    fields={fields}
  />
}

export default ForgottenAuth

import React from 'react'
import PropTypes from 'prop-types'
import { InfoBlockPanelContainer, InfoBlocksContainer } from './InfoBlockPanel.style'
import InfoBlock from '../../InfoBlock'
import { Text } from '../../generic'

function InfoBlockPanel({ title, description, infoBlocks, smallDescription, background }) {
  return (
    <InfoBlockPanelContainer background={background}>
      <Text heading primary uppercase><h2>{title}</h2></Text>
      <p />
      {description && <Text large={!smallDescription} style={{ width: "60%", letterSpacing: "0.38px", textAlign: "center" }}><p>{description}</p></Text>}
      <InfoBlocksContainer>
        {infoBlocks && infoBlocks.map((infoBlock, i) => <InfoBlock key={i} {...infoBlock} />)}
      </InfoBlocksContainer>
    </InfoBlockPanelContainer>
  )
}

InfoBlockPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  infoBlocks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object
  }))
}

export default InfoBlockPanel
import styled from '@emotion/styled'

const InfoBlockPanelContainer = styled.div`
  background-color: ${props => props.background ? props.theme.colors[props.background] : "white"};
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 115px;
  padding-bottom: 115px;
`

const InfoBlocksContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
`

export {
  InfoBlockPanelContainer,
  InfoBlocksContainer
}
const relationshipTypes = [
  { value:"MOTHER_IN_LAW", label:"Mother in Law" },
  { value:"FATHER_IN_LAW", label:"Father in Law" },
  { value:"FATHER", label:"Father" },
  { value:"MOTHER", label:"Mother" },
  { value:"GREAT_GRANDPARENTS", label:"Great Grandparents" },
  { value:"GREAT_GRANDPARENTS_IN_LAW", label:"Great Grandparents in Law" },
  { value:"GRANDPARENTS", label:"Grandparents" },
  { value:"GRANDPARENTS_IN_LAW", label:"Grandparents in Law" },
  { value:"AUNT", label:"Aunt" },
  { value:"UNCLE", label:"Uncle" },
  { value:"BROTHER", label:"Brother" },
  { value:"BROTHER_IN_LAW", label:"Brother in Law" },
  { value:"SISTER", label:"Sister" },
  { value:"SISTER_IN_LAW", label:"Sister in Law" },
  { value:"FIRST_COUSIN", label:"First Cousin" },
  { value:"SON_IN_LAW", label:"Son in Law" },
  { value:"DAUGHTER_IN_LAW", label:"Daughter in Law" },
  { value:"NEPHEW", label:"Nephew" },
  { value:"NIECE", label:"Niece" },
  { value:"SON", label:"Son" },
  { value:"DAUGHTER", label:"Daughter" },
  { value:"OTHER", label:"Other"},
  { value:"FRIEND", label:"Friend"},
  { value:"FAMILY_FRIEND", label:"Family Friend"},
  { value:"SPOUSE", label:"Spouse"},
  { value:"HUSBAND", label:"Husband"},
  { value:"WIFE", label:"Wife"},
  { value:"GRANDMOTHER", label:"Grandmother"},
  { value:"GRANDMOTHER_IN_LAW", label:"Grandmother in Law"},
  { value:"GRANDFATHER", label:"Grandfather"},
  { value:"GRANDFATHER_IN_LAW", label:"Grandfather in Law"},
  { value:"GRANDSON", label:"Grandson"},
  { value:"GRANDDAUGHTER", label:"Granddaughter"},
]

export default relationshipTypes

import styled from '@emotion/styled'
import { Modal } from '../../generic'

const StyledModal = styled(Modal)`
  background-color: ${props => `${props.theme.colors.dark}E6`}
`

const ConfirmModalContentsContainer = styled.div`
  margin-top: -9px;
  max-width: 600px;
  background-color: #F0F0F0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px;
`

const ConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`

const ConfirmModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 86%;
`

const MokuPopup = styled.img`
  z-index: 2;
`

export {
  StyledModal,
  ConfirmModalContentsContainer,
  ConfirmButtonsContainer,
  ConfirmModalContainer,
  MokuPopup
}
import React from 'react'
import { Row, Col } from '../../generic/grid'
import Text from '../../generic/Text'
import { MokuNotePanelContainer, CloseButton, MokuChatCustom } from './MokuNotePanel.style'
import theme, { IMeerkatWebColors } from '../../../theme.style'
import { RowSpacer } from '../../generic/Layouts'
import { close } from '../../../images'

interface Props {
  title: string
  description: string | React.ReactChild
  visible?: boolean
  color?: keyof IMeerkatWebColors
  transparent?: boolean
  showClose?: boolean
  onClose?: () => void
  footer?: React.ReactChild
  children?: React.ReactChild
  noMoku?: boolean
}

const MokuNotePanel: React.FC<Props> = ({
  title,
  description,
  visible = true,
  color,
  transparent,
  showClose,
  onClose,
  footer,
  children,
  noMoku,
}) => (
  <MokuNotePanelContainer color={color} visible={visible} transparent={transparent}>
    {showClose && <CloseButton src={close} onClick={onClose} />}
    <Text bold size={13}>
      {title}
    </Text>
    <RowSpacer height={4} />
    <Row>
      <Col centerVertical size={1}>
        <Text size={13}>{description}</Text>
      </Col>
      <Col centerVertical size={0} style={{ justifyContent: 'end', paddingLeft: '5%' }}>
        {!noMoku && <MokuChatCustom color={color && theme.colors[color]} />}
      </Col>
    </Row>
    {footer && footer}
    {/*@ts-ignore*/}
    {children && <Row padTop="16px">{children}</Row>}
  </MokuNotePanelContainer>
)

export default MokuNotePanel

import React from 'react'
import { MokuHoldingCardContainer, MokuPopup, CardContainer } from './MokuHoldingCard.style'
import mokuPopup from '../../../images/mokuPopup.svg'

function MokuHoldingCard({ children, noMoku }) {
  return <MokuHoldingCardContainer>
    {!noMoku && <MokuPopup><img src={mokuPopup} /></MokuPopup>}
    <CardContainer>
      {children}
    </CardContainer>
  </MokuHoldingCardContainer>
}

export default MokuHoldingCard

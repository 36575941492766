import React from 'react'
import { SendDocumentContainer } from './SendDocumentModal.style'
import { Modal, Text, Button } from '../../generic'
import { FormProvider, FormField, SubmitButton } from 'react-form-helper'
import { renderInput } from '../../generic/form'

function SendDocumentModal({ open, setOpen, title, document, label, zIndex, onSend, validate }) {
  const fields = {
    to: {
      label,
      type: "text",
      validate
    }
  }

  return <Modal open={open} setOpen={setOpen} zIndex={zIndex} redrawOnOpen>
    <SendDocumentContainer>
      <Text size={16} color="primary">{title}</Text>
      <p />
      {/*<Text size={13}>Your document</Text>
      <Text size={13}>{document}</Text>
      <Text size={13}>will be sent to</Text>
      <p />*/}
      <FormProvider 
        name="sendDocModal"
        fields={fields}
        onSubmit={({ to }) => { onSend && onSend(to); setOpen(false) }}
        renderInput={renderInput}
        renderLabel={() => {
          const renderer = () => <div />;

          return renderer;
        }}
        mode="add"
      >
        <FormField name="to" />
        <p />
        <SubmitButton renderSubmitButton={({ submit, invalid, submitting }) => <Button disabled={invalid} submitting={submitting} onClick={submit}>Send</Button>}/>
      </FormProvider>
    </SendDocumentContainer>
  </Modal>
}

export default SendDocumentModal

import styled from '@emotion/styled'

const TitleImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-height: 140px;
  margin-bottom: 14px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${props => props.bottomText ? "flex-end" : "center"};
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin: 0
  }
`

export {
  TitleImageContainer,
  TextContainer,
  ImageContainer
}

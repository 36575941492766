import React from 'react'
import Auth from '../../components/Auth'
import { ActivityIndicator } from '../../components/generic'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

function LogInPage() {
  if (typeof window === 'undefined') {
    return <ActivityIndicator />
  }

  return <Layout noHeader noMoku noFooter>
    <SEO title="Sign In" />
    <Auth login />
  </Layout>
}

export default LogInPage
import moment from 'moment'
import gql from 'graphql-tag'
import { getField } from 'react-form-helper/dist/utils'
// TODO: Refactor and Clean up. Remove logging

const isRequired = (value) => {
  return (value || value === false ? undefined : 'This field is required')
}

const isNumber = (value) => {
  return (!value || value.length === 0 || (parseInt(value) === 0 && /^[0-9\.]+$/.test(value)) || /^[0-9]+\.?[0-9]*$/i.test(value) 
? undefined
    : 'This field needs to be a number like 123 or 123.45')
}

const isValidEmail = (value) =>
  !value || value.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? undefined
  : 'Invalid email address';

const allowedCellPhonePrefixes = ['06', '07', '08']

const isValidCellphoneNumber = (value) => {
  return !value || value.length === 0 || allowedCellPhonePrefixes.map(prefix => new RegExp('^' + prefix + '[0-9]{8}$')).some(rx => rx.test(value)) ? undefined : 'Invalid cellphone number'
}

const isRequiredForForeignID = (value, allValues, props, name) => {
  const { idType, idNumber } = getFieldPrefix(name) ? getField(allValues, getFieldPrefix(name)) : allValues

  if (idType && idType !== "rsa_id") {
    if (!value && idNumber) return "Required for non-South Africans"
  }

  return undefined
}

const isRequiredForThirdPartyAccount = (value, allValues, props, name) => {
  const { ownAccount } = getFieldPrefix(name) ? getField(allValues, getFieldPrefix(name)) : allValues

  if (ownAccount === "N") {
    if (!value) return "Required for third party accounts"
  }

  return undefined
}

const isRequiredForPayrolls = (payrolls) => (value, allValues) => {
  const { payrollSystem } = allValues

  if (payrolls.includes(payrollSystem)) return isRequired(value)
  
  return undefined
}

const isValidRsaIdNumber = (value)  => {
  if (!value) {
    return undefined
  }
  if (value == '0000000000000') return 'Invalid ID number';
  if (!/^\d{10}[0-1]\d{2}$/.test(value)) return 'Invalid ID number';
  const n = value;
  const p1 = parseInt(n[0], 10) + parseInt(n[2], 10) + parseInt(n[4], 10) + parseInt(n[6], 10) + parseInt(n[8], 10) + parseInt(n[10], 10);
  const p2 = (parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2).toString();
  let p3 = 0;
  for (let i = 0; i < p2.length; i++) {
    p3 += parseInt(p2[i]);
  }
  const check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
  const check_char = check > 9 ? check.toString()[1] : check.toString();
  if (check_char != value[12]) return 'Invalid ID number';
  return undefined;
}

const getFieldPrefix = name => name.split('.').slice(0, -1).join('.')

const isValidIdNumber = (value, allFields, props, name) => {
  const { idType } = getFieldPrefix(name) ? getField(allFields, getFieldPrefix(name)) || {} : allFields

  if (!idType || ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1) {
    return isValidRsaIdNumber(value)
  }

  return undefined
};


// TODO: Refactor from here ...

const GET_COVER = gql`
  query getTotalCover($idNumber: String!) {
    getTotalCover(idNumber: $idNumber) 
  }
`

const isDate = (value) => {
  return undefined
  // return "Invalid Date"
}

const minAge = {
  POLICY_HOLDER: 18,
  SPOUSE: 18,
  CHILD: 0,
  PARENT: 18,
}

const maxAge = {
  POLICY_HOLDER: 60,
  SPOUSE: 60,
  CHILD: 17,
  PARENT: 75,
}

const getFieldName = (name) => {
  if (name) {
    var fieldName = name.split(".")

    return fieldName[1]
  }
  return undefined
}

const getIndex = (name) => {
  if (name) {
    var openBracket= name.indexOf('[')
    var closeBracket = name.indexOf(']')
    var index = name.substr(openBracket + 1, (closeBracket - openBracket - 1) )

    return index
  }
    else return 0
}

const forMemberTypes = (memberTypes, validators) => (value, allFields, props, name) => {
  if (name && allFields.members && allFields.members.length > 0)  {
    const index = getIndex(name)
    const memberType = allFields.members[index] && allFields.members[index].memberType

    if (memberTypes.includes(memberType)) {
      return validators instanceof Array ? validators.map(validator => validator(value, allFields, props, name)) : validators(value, allFields, props, name)
    }
  }
    return undefined
}

const isRequiredIfNotSA = (value, allFields, props, name) => {

  const index = getIndex(name)
  const idType = allFields.members[index].idType

  if ( idType && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) === -1 ) {
   return (value ? undefined : "This is required for non-South African ID's")
  }
  else return undefined
}

const doesDOBMatchIdNumber = (value, allFields, props, name) => {
  if (name && !isNaN(value) ) {
    // get the member's idType
    const member = getFieldPrefix(name) ? getField(allFields, getFieldPrefix(name)) : allFields
    const { idType } = member

    if (name && idType && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1) {
      if (value && member && member.idNumber) {
        const DOBFromIdNumber = getDOBFromID(member.idNumber, member.idType)
        const DOB = moment(member.DOB || member.birthDate)

        if (DOBFromIdNumber.diff(DOB, 'days') === 0) {
          return undefined
        } else {
          return '"Birth Date" does not match ID Number'
        }
      }
    }
  }
  return undefined
}

const getDOBFromID = (idNumber, idType) => {
  if (idNumber && idType && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1) {
    var yy = idNumber.slice(0, 2)
    var dob = `19${idNumber.slice(0, 6)}`
    if (yy < 25 ) {
      dob = `20${idNumber.slice(0, 6)}`
    }
    var birthday = moment(dob, 'YYYYMMDD')
    return birthday;
  }
  return undefined;
}

async function getCover (client, idNumber, coverAmount) {
  const response = await client.query({ query: GET_COVER, variables:{ idNumber } })
  const data = await response
  if ( data.data.getTotalCover + coverAmount > 100000) {
    console.log('isExistingOver100K: too much data is', data.data.getTotalCover)
    return "Total cover must be less than R100,000 (person already has R" + data.data.getTotalCover + ")"
  }
  else {
    console.log('isExistingOver100K: data is', data.data.getTotalCover)
    return undefined
  } 
}  

const isExistingOver100K = (value, allFields, props, name) => {
  
  return undefined

  // if (!value ) return undefined
  
  // const { coverAmount } = allFields
  // const { client } = props
  // const idNumber = value
  // const tooMuch = getCover(client, idNumber, coverAmount)
  // console.log(' isExistingOver100K:', tooMuch)
  // return tooMuch
}

const doesAgeAtSignupMatchDOB = (value, allFields, props, name) => {
  let { idType } = allFields
  if (!idType) { idType = "SOUTH AFRICA"}
  
  if (value && name ) {
    let memberIndex = getIndex(name)
    let member = allFields.members[memberIndex]

    if (allFields.members && member  && (member.DOB || (member.idNumber && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1 )))  {
      const DOB = member.idNumber && idType && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1 ? getDOBFromID(member.idNumber, member.idType ) : moment(member.DOB)
      const currentAgeFromDOB = moment().diff(DOB, 'years')
      const ageAtSignup = member.ageAtSignup
      if ((member.memberType === "POLICY_HOLDER" || member.memberType === 'CHILD') && !DOB  && ! ageAtSignup ) return 'Enter either Age or ID or DOB'
      if (!ageAtSignup || currentAgeFromDOB === 0 || currentAgeFromDOB == ageAtSignup) {
        return undefined
      } else {
        return 'Age given does not match DOB'
      }
    }
  }
  return undefined
}

const doesDobMatchAge = (value, allFields, props, name) => {
  const member = getFieldPrefix(name) ? getField(allFields, getFieldPrefix(name)) : allFields

  if (value && name ) {
      const currentAgeFromDOB = moment().diff(value, 'years')
      const ageAtSignup = member.ageAtSignup || member.age

      if (currentAgeFromDOB == ageAtSignup) {
        return undefined
      } else {
        return 'Your date of birth does not match age given'
      }
    }
  return undefined
}


const doesAgeAtSignupMatchId = (value, allFields, props, name) => {  
  if (name && !isNaN(value) ) {
    // get the member's idType
    const index = getIndex(name)
    const idType = allFields.members[index].idType
  
    if (idType && ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1) {
      
      let memberIndex = getIndex(name)
      let member = allFields.members[memberIndex]
      if (member && member.idNumber) {
        const DOB = getDOBFromID(member.idNumber, member.idType)
        const currentAgeFromDOB = moment().diff(DOB, 'years')
        const ageAtSignup = member.ageAtSignup
        if (member.memberType === "POLICY_HOLDER" && !DOB  && ! ageAtSignup ) {
          return 'Enter either Age or Birth Date'
          }
        if (currentAgeFromDOB == ageAtSignup) {
          return undefined
        } else {
          return 'Age given does not match ID number'
        }
      }
    }
  }
  return undefined
}

const idNumberAgeCheck = (min = 18, max = 60) => (value) => {
  const age = getAgeFromID(value)

  if (!age || age < min || age > max) {
    return "Age must be between " + min + " and " + max + " to be covered"
  }
  return undefined;
}

const getAgeFromDOB = (dob) => { 
  return moment().diff(moment(dob), 'years');}

  const getAgeFromID = (idNumber, idType) => {
  let idTypeUpper 

  if (idType) idTypeUpper = idType.toUpperCase()

    if (idNumber && (!idType || ["RSA_ID","SOUTH AFRICAN","SOUTH AFRICA"].indexOf(idTypeUpper) > -1)) {
      const DOBFromIdNumber = moment(`${parseInt(idNumber.slice(0,2)) > 25 ? "19" : "20"}${idNumber.slice(0, 6)}`, 'YYYY MM DD');
      return getAgeFromDOB(DOBFromIdNumber);
    }
    return undefined;
  };

const sex = (idNumber) => parseInt(idNumber.substr(6,4)) < 5000 ? "FEMALE" : "MALE"

const isInteger = value => value && !/^\d+$/.test(value) ? 'Whole numbers only' : undefined

const ageCheck = (value, allValues, props, name) => {
  if (name) {
    const { memberType } = getFieldPrefix(name) ? getField(allValues, getFieldPrefix(name)) : allValues

    const min = minAge[memberType]
    const max = maxAge[memberType]

    if (value < min || value > max) {
      return `Age must be between ${min} and ${max}, check age`
    }
  return undefined
  }
}

const isValidDay = (value) => {
if (value && value !== null && value !== undefined) {
  if (value < 1 || value > 32 ) return `Day must be between 1 and 31`
}
return undefined
}


const setYear = (date) => {
if (date.length === 6) {
  var yy = date.slice(0, 2)
  var fullDate = `19${date.slice(0, 6)}`
  if (yy < 25 ) {
    fullDate = `20${date.slice(0, 6)}`
  }
  return fullDate
}
else return date
}
 
const isMemberValidAge = (type) => (value)  => {
  const min = minAge[type]
  const max = maxAge[type]

  if (value && (value < min || value > max) ) {
    return `Age must be between ${min} and ${max}, check age`
  }
  else return undefined
}

const isValidAge = (value, allFields, props, name) => {
  if (name && value) {    
    if (value.toString().length > 2) {
      return ageCheck(moment().diff(moment(value), 'year'), allFields, props, name)
    } else {
      return ageCheck(value, allFields, props, name)
    }
  }

  return undefined
}

function addDigits(number, recur=true){// 1236 -> 1+2+3+6 = 12 -> 1+2=3 
  const asString = `${number}`;
  let total = 0;
  for(const d of asString){
    total += parseInt(d);
  }
  if(total > 9 && recur) return addDigits(total, true);
  return total;//or recur
}

const isValidRsaTaxNumber = (value) => {
  var asString = `${value}`;
  if(value.length != 10) return "Invalid Tax Number";
  if(!(asString[0] == 9 || asString[0] <= 3)) return "Invalid Tax Number";//(0,1,2,3 or 9)
  let total = 0;
  let digit;
  let checkDigit = parseInt(asString[9]);
  for(let i=0; i<9; i++){
    digit = parseInt(asString[i]);
    if(i%2 == 0){//*2
      total += addDigits(digit*2);
    }else{
      total += addDigits(digit);
    }
  }
  const totalAsString = `${total}`;
  const lastDigit = parseInt(totalAsString[totalAsString.length-1]);
  if(lastDigit == 0 && checkDigit == 0){//passes
    return;
  } else {
    if(10 - (total%10) == checkDigit ){//passes
      return;
    }
  }
  return "Invalid Tax Number";
}
/* tests:
  "0001339050" -> should pass

*/

export {
  isRequired,
  isNumber,
  isValidEmail,
  isValidCellphoneNumber,
  isValidRsaIdNumber,
  isValidIdNumber,
  isRequiredForForeignID,
  isRequiredForPayrolls,
  isRequiredForThirdPartyAccount,
  isValidRsaTaxNumber,
  
  // Needs Refactor
  doesDobMatchAge,isRequiredIfNotSA, isExistingOver100K, isMemberValidAge, getAgeFromDOB, isDate, sex, forMemberTypes, getIndex, getAgeFromID, isInteger, getDOBFromID, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, idNumberAgeCheck
}
import React from 'react'
import { MeerkatLogo, AnnouncementImage, AnnouncementModalContainer, AnnouncementCard, AnnouncementModalContentContainer, AnnouncementModalActionContainer, CloseButton, BlobBackground } from './AnnouncementModal.style'
import { Modal, Text, Button } from '../../generic'
import { mokuPopup, closeCircleSolid, largeBlob, announcement, logo } from '../../../images'
import { MokuPopup } from '../WelcomeModal/WelcomeModal.style'

function AnnouncementModal({ open, setOpen, title, description, action, closeTitle }) {
  return <Modal open={open}>
    <AnnouncementModalContainer>
      <BlobBackground src={largeBlob} />
      <CloseButton src={closeCircleSolid} alt="X" onClick={() => setOpen(false)} />
      <AnnouncementModalContentContainer>
        <MeerkatLogo src={logo} alt="Meerkat" />
        <AnnouncementImage src={announcement} alt={title} />
        <Text size={24} bold>{title}</Text>
        <p />
        <Text size={16} bold>{description}</Text>
      </AnnouncementModalContentContainer>
      <AnnouncementModalActionContainer>
        {action && <Button onClick={action && action.onClick}>{action && action.title}</Button>}
        <Button noBorder inverted onClick={() => setOpen(false)}>{closeTitle}</Button>
      </AnnouncementModalActionContainer>
    </AnnouncementModalContainer>
  </Modal>
}

export default AnnouncementModal

import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const IntroCurveContainer = styled.div`
  position: relative;
  height: calc(100vh - 100px);
  background-color: ${props => props.inverted ? "white" : props.theme.colors.yellow};
  overflow: hidden;

  ${device.laptopL`
    height:  calc(100vh - 75px);
  `}  

  ${device.laptop`
    height:  calc(100vh - 50px);
  `}
`

const IntroCurveImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;

  ${device.tablet`
    width: 100%;
    bottom: 100px;
  `}
`

const BelowIntroCurveImage = styled.div`
  display: none;
  background-color: ${props => props.inverted ? props.theme.colors.yellow : "white"};
 

  ${device.tablet`
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0px;
  `}
`

const ContainedImageContainer = styled.div`
  position: absolute;
  right: 8%;
  bottom: 8%;
  height: 70%;
  width: 50%;

  img {
    object-fit: contain !important;
  }

  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    height: 100%; 
  }
`

const IntroCurveTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.smallDescription ? "40%" : "60%"};
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 7.8%;

  ${device.tablet`
    display: flex;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    height: 70%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `}
`

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${device.tablet`
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `}
`

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14px;

  ${device.tablet`
    margin-right: 0px;
    margin-bottom: 14px;
  `}
`

export {
  IntroCurveContainer,
  IntroCurveImageContainer,
  BelowIntroCurveImage,
  IntroCurveTextContainer,
  ContainedImageContainer,
  ActionButtonsContainer,
  ActionButtonContainer
}
import styled from '@emotion/styled'

const GetInfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #FFFFFF;
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 4px;
  img {
    margin: 0
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  margin-right: 20px;
  
  img {
    min-width: 72px;
    max-width: auto;
    margin: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
`

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin: 0;
    min-width: 6px;
  }
`

export {
  GetInfoCardContainer,
  ImageContainer,
  TextContainer,
  ArrowContainer
}

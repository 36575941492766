// Matching Bankserv's bank info
const BANKS = [
  { "label": "ABSA Bank", "value": "632005", "paymentType": "NAEDO" },
  { "label": "Capitec Bank", "value": "470010", "paymentType": "NAEDO" },
  { "label": "FNB", "value": "250655", "paymentType": "NAEDO" },
  { "label": "Nedbank", "value": "198765", "paymentType": "NAEDO" },
  { "label": "Standard Bank", "value": "051001", "paymentType": "NAEDO" },
  { "label": "African Bank", "value": "430000", "paymentType": "NAEDO" },
  { "label": "Bidvest Bank", "value": "462005", "paymentType": "NAEDO" },
  { "label": "Investec Bank", "value": "580105", "paymentType": "DO" },
  { "label": "Mercantile", "value": "450905", "paymentType": "NAEDO" },
  { "label": "Ubank", "value": "431010", "paymentType": "NAEDO" },
  { "label": "Al Baraka", "value": "800000", "paymentType": "DO" },
  { "label": "Bank of China", "value": "686000", "paymentType": "DO" },
  //{ "label":"Bank of Taiwan",	"value":null, "paymentType":"DO"},
  { "label": "BNP Paribas", "value": "688000", "paymentType": "DO" },
  //{"label":"BofA Securities", "value":null, "paymentType":"DO"},	
  { "label": "China Construction Bank", "value": "586666", "paymentType": "DO" },
  { "label": "Citibank", "value": "350005", "paymentType": "DO" },
  //{ "label":"Deutsche Bank", "value":null	, "paymentType":"DO"},
  { "label": "Discovery Bank", "value": "679000", "paymentType": "DO" },
  { "label": "FinBond Mutual Bank", "value": "589000", "paymentType": "DO" },
  //{"label":"GBS Mutual Bank", "value":null	, "paymentType":"DO"},
  //{"label":"Goldman Sachs", "value":	null, "paymentType":"DO"},
  { "label": "Grindrod Bank", "value": "584000", "paymentType": "DO" },
  { "label": "Grobank", "value": "10410500", "paymentType": "DO" },
  //{"label":"Habib Overseas Bank", "value":null, "paymentType":"DO"},
  //{"label":"HBZ", "value":null	, "paymentType":"DO"},
  { "label": "HSBC", "value": "587000", "paymentType": "DO" },
  { "label": "ICICI", "value": "362000", "paymentType": "DO" },
  { "label": "Ithala", "value": "754126", "paymentType": "DO" },
  { "label": "JPMorgan Chase", "value": "432000", "paymentType": "DO" },
  { "label": "Postbank", "value": "460005", "paymentType": "DO" },
  { "label": "Sasfin", "value": "683000", "paymentType": "DO" },
  { "label": "Societe Generale", "value": "351005", "paymentType": "DO" },
  { "label": "Standard Chartered", "value": "730020", "paymentType": "DO" },
  { "label": "Bank of India", "value": "801000", "paymentType": "DO" },
  { "label": "TymeBank", "value": "678910", "paymentType": "DO" }
]

  export default BANKS
  
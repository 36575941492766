import React from 'react'
import { InformationRowContainer, InformationRowLayoutContainer, InformationRowTitleContainer, InformationRowValueContainer } from './InformationRow.style'
import Text from '../../generic/Text'

function InformationRow({ title, value }) {
  return <InformationRowContainer>
    <InformationRowTitleContainer>
      <Text size={13}>{title}</Text>
    </InformationRowTitleContainer>
    <InformationRowValueContainer>
      <Text size={13} bold>{value}</Text>
    </InformationRowValueContainer>
  </InformationRowContainer>
}

function InformationRowLayout({ title, value }) {
  return <InformationRowContainer>
    <InformationRowTitleContainer>
      {title}
    </InformationRowTitleContainer>
    <InformationRowValueContainer>
      {value}
    </InformationRowValueContainer>
  </InformationRowContainer>
}

export {
  InformationRowLayout
}

export default InformationRow

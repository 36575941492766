import React from "react";
import Modal from "../../generic/Modal";
import Wizard from "../Wizard";
import { WizardModalContentsHeader } from './WizardModal.style'

function WizardModal({
  name,
  open,
  setOpen,
  steps,
  fields,
  loading,
  initialValues,
  children,
  renderBodyHeader,
  renderBodyFooter,
  renderContentContainer,
  zIndex,
  closeComponent,
  ...props
}) {
  return (
    <Modal open={open} setOpen={setOpen} zIndex={zIndex}>
      { closeComponent ? //? TODO: no sure about this
        <WizardModalContentsHeader>
          { closeComponent }
        </WizardModalContentsHeader>
        : <></> 
      }
      <Wizard
        name={name}
        open={open}
        setOpen={setOpen}
        steps={steps}
        fields={fields}
        loading={loading}
        initialValues={initialValues}
        renderBodyHeader={renderBodyHeader}
        renderBodyFooter={renderBodyFooter}
        renderContentContainer={renderContentContainer}
        zIndex={zIndex}
        noCloseButton={closeComponent!=null}
        {...props}
      >
        {children}
      </Wizard>
    </Modal>
  );
}

export default WizardModal

import React from 'react'
import { GetInfoCardContainer, ImageContainer, TextContainer, ArrowContainer } from './GetInfoCard.style'
import { Text } from '../../generic'
import { Row } from '../../generic/grid'
import { rightArrow } from '../../../images/itemRow'

function GetInfoCard({ title, description, image, onClick }) {
  return <GetInfoCardContainer onClick={onClick}>
    <Row>
      <ImageContainer>
        <img src={image} alt={title} />
      </ImageContainer>
      <TextContainer>
        <Text size={14} bold>{title}</Text>
        <div style={{ height: "4px" }} />
        <Text size={10}>{description}</Text>
      </TextContainer>
      <ArrowContainer>
        <img src={rightArrow} alt=">" />
      </ArrowContainer>
    </Row>
  </GetInfoCardContainer>
}

export default GetInfoCard

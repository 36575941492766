import styled from '@emotion/styled'

const WizardModalContentsContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 400px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
`

const WizardModalContentsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
`

const WizardModalContentsBodyContainer = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100vh;
`

const WizardModalContentsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  #justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 400px;
  margin: auto;
  min-height: calc(100vh - 90px);
  flex-grow: 1;
`
const MeerkatLogo = styled.img`
  height: 28px;
  margin: 0;
  margin-top: 14px;
`

const CloseButton = styled.img`
  position: absolute;
  right: 15px;
  top: 7px;
  z-index: 3;
  margin: 0;
`

const MokuHeadImageContainer = styled.div`
  width: ${props => props.size || "95"}px;
  height: ${props => props.size || "95"}px;
  border-radius: ${props => props.size / 2 || "47.5"}px;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
`

const MokuHeadImage = styled.img`
  width: 95%;
  height: 95%;
  margin: 0;
`

const WizardModalContentsFooter = styled.div`
`

export {
  WizardModalContentsContainer,
  WizardModalContentsHeader,
  WizardModalContentsBodyContainer,
  WizardModalContentsBody,
  MeerkatLogo,
  CloseButton,
  MokuHeadImageContainer,
  MokuHeadImage,
  WizardModalContentsFooter
}

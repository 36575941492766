import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Circle = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  width: ${props => props.small ? "16px" : "24px"};
  height: ${props => props.small ? "16px" : "24px"};
  border-radius: ${props => props.small ? "8px" : "12px"};
`

const TimelineLine = styled.div`
  display: flex;
  ${props => props.height ? css`
    height: ${props.height};
  ` : css`
    flex-grow: 1;
  `}
  
  flex-direction: row;
  width: 4px;
  ${props => !props.hidden && css`
    background-color: ${props.theme.colors[props.color]};
  `}
  margin-left: 12px;
  margin-right: 12px;
`

export {
  Circle,
  TimelineLine
}

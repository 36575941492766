import React from 'react'
import { ImageHeaderContainer, BackgroundImage, LeftNavigationImage, RightNavigationImage } from './ImageHeader.style'
import { backWhite, moreWhite } from '../../../images/navigation'
import { Text } from '../../generic'

function ImageHeader({ title, image, onBack }) {
  return <ImageHeaderContainer>
    <BackgroundImage src={image} alt={title} />
    <LeftNavigationImage src={backWhite} alt="<" onClick={onBack} />
    <RightNavigationImage src={moreWhite} alt="..." />
    <Text size={26} white bold>{title}</Text>
  </ImageHeaderContainer>
}

export default ImageHeader

import React from 'react'
import { WelcomeModalContainer, WelcomeModalContents, MokuPopup, CutoutCard, WelcomeModalHeader, MokuCircle, CutoutCardContainer } from './WelcomeModal.style'
import { Modal, Button, Text } from '../../generic'
import { mokuPopup, mokuCircle } from '../../../images'
import TipItem from '../../dashboard/TipItem'

function WelcomeModal({ open, setOpen, description, name, actions = [], actionButtons = [] }) {
  return <Modal open={open}>
    <WelcomeModalContainer>
      <WelcomeModalContents>
        <p />
        <WelcomeModalHeader>
          <Text size={18}>Welcome</Text>
          <Text size={48} bold>{name}</Text>
        </WelcomeModalHeader>
        <MokuPopup src={mokuPopup} alt="Moku" />
        <CutoutCardContainer>
          <CutoutCard>
            <Text size={13} bold>Hello, Moku here!</Text>
            <p />
            <Text size={13}>{description}</Text>
            <p />
            {actions.map((action, i) => (
              <TipItem key={i} title={action.title} icon={action.icon} />
            ))}
            <p />
            {actionButtons.map((action, i) => (
              <Button key={i} fullWidth style={{ marginBottom: "7px" }} onClick={action.onClick}>{action.title}</Button>
            ))}
            
          </CutoutCard>
          <MokuCircle src={mokuCircle} alt="Moku" />
        </CutoutCardContainer>
        <p />
        <Text size={13} onClick={() => setOpen(false)}>Dismiss</Text>
      </WelcomeModalContents>
      
    </WelcomeModalContainer>
  </Modal>
}

export default WelcomeModal

import React from 'react'
import { TitleImageContainer, TextContainer, ImageContainer } from './TitleImage.style'
import { Text } from '../../generic'

function TitleImage({ title, title2, description, image, bottomText }) {
  return <TitleImageContainer>
    <TextContainer bottomText={bottomText}>
      <Text size={26} bold>{title}</Text>
      <div style={{ height: "14px" }} />
      {title2 && <Text size={18} bold>{title2}</Text>}
      <Text size={13}>{description}</Text>
    </TextContainer>
    <ImageContainer><img src={image} alt={title} /></ImageContainer>
  </TitleImageContainer>
}

export default TitleImage

import { IMeerkatWebColors } from '../theme.style'

function getRiskProfile(data?: { compuScore?: number }): {
  innerText: string
  value: number
  color: keyof IMeerkatWebColors
} {
  if (!data || !data.compuScore) {
    return {
      innerText: 'Unknown',
      value: 0,
      color: 'grey5',
    }
  } else if (data.compuScore < 595) {
    return {
      innerText: 'Very High',
      value: data.compuScore,
      color: 'alertRed',
    }
  } else if (data.compuScore < 611) {
    return {
      innerText: 'High',
      value: data.compuScore,
      color: 'red',
    }
  } else if (data.compuScore < 629) {
    return {
      innerText: 'Average',
      value: data.compuScore,
      color: 'alertYellow',
    }
    // eslint-disable-next-line no-dupe-else-if
  } else if (data.compuScore < 629) {
    return {
      innerText: 'Low',
      value: data.compuScore,
      color: 'primary',
    }
  } else {
    return {
      innerText: 'Minimum',
      value: data.compuScore,
      color: 'primary',
    }
  }
}

function getAffordabilitySummary(
  affordability: number,
  income: number
): { color: keyof IMeerkatWebColors; innerText: string } {
  if (affordability < (income || 10000) * 0.1) {
    return {
      color: 'alertRed',
      innerText: 'Poor',
    }
  } else if (affordability < (income || 10000) * 0.3) {
    return {
      color: 'alertYellow',
      innerText: 'Average',
    }
  } else {
    return {
      color: 'primary',
      innerText: 'Good',
    }
  }
}

export { getRiskProfile, getAffordabilitySummary }

import styled from '@emotion/styled'

const ImageHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 157px;
  padding: 16px;
  margin: -16px;
`

const BackgroundImage = styled.img`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LeftNavigationImage = styled.img`
  position: absolute;
  top: 24px;
  left: 16px;
`

const RightNavigationImage = styled.img`
  position: absolute;
  top: 24px;
  right: 16px;
`

export {
  LeftNavigationImage,
  RightNavigationImage,
  ImageHeaderContainer,
  BackgroundImage
}

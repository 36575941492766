import styled from '@emotion/styled'

const WelcomeModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.colors.grey};
  opacity: 0.95;
  backdrop-filter: blur(4px);
`

const WelcomeModalHeader = styled.div`
  width: 291px
`

const WelcomeModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MokuPopup = styled.img`
  z-index: 2;
  margin: 0;
`

const CutoutCardContainer = styled.div`
  position: relative;
`

const CutoutCard = styled.div`
  position: relative;
  justify-content: space-around;
  background: white;
  width: 291px;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  padding: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  margin-top: -9px;
  overflow: hidden;

  &:before {
    transition-duration: 0.5s;
    border-radius: 29px;
    transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
    content: '';
    width: 58px;
    height: 58px;
    background-color:${props => props.theme.colors.grey};
    position:absolute;
    bottom:-29px;
    right:-29px;
    overflow-y: hidden;
    box-shadow: inset 0px 0 8px 0px rgba(64, 67, 87, 0.12);
  }
}`

const MokuCircle = styled.img`
  position: absolute;
  right: -15px;
  bottom: -14px;
  margin 0;
`

export {
  WelcomeModalContainer,
  WelcomeModalContents,
  WelcomeModalHeader,
  MokuPopup,
  CutoutCard,
  CutoutCardContainer,
  MokuCircle
}

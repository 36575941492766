import React from 'react'
import { InfoCardContainer } from './InfoCard.style'
import Text from '../../generic/Text'

function InfoCard({ title, description }) {
  return <InfoCardContainer>
    <Text size={16} bold>{title}</Text>
    <div style={{ height: "10px "}} />
    <Text size={13}>{description}</Text>
  </InfoCardContainer>
}

export default InfoCard

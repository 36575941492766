import styled from '@emotion/styled'
import theme from '../../../../theme.style'
import { Modal } from '../../../generic'

const CustomModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  background: rgba(64, 67, 87, 0.8);
  align-items: center;
  padding: 16px;
`

const MokuCustomModal = styled(Modal)`
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 40%, #9d9d9d 100%);
  align-items: flex-end;
  padding: 16px;
`

//TODO: add color prop?
const OverlayDiv = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

//TODO: add color prop?
const ModalContainer = styled.div`
  width: 100%;
  ${() => `background:${theme.colors.lightGreen};`}
  border-radius: 8px;
  padding: 33px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

//TODO: add color prop?
const MokuModalContainer = styled.div`
  width: 100%;
  ${() => `background:${theme.colors.lightGreen};`}
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const MokuIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`

export { OverlayDiv, ModalContainer, MokuModalContainer, CustomModal, MokuCustomModal, MokuIconContainer }

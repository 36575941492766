import styled from '@emotion/styled'

const InformationRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 8px;
  margin-bottom: 8px;
`
const InformationRowLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  // margin-top: 8px;
  // margin-bottom: 8px;
`

const InformationRowValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const InformationRowTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
`

export {
  InformationRowContainer,
  InformationRowValueContainer,
  InformationRowTitleContainer,
  InformationRowLayoutContainer
}

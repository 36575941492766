import React from 'react'
import {
  BlogCardContainer,
  BlogCardDescriptionText,
  BlogCardImage,
  BlogCardImageContainer,
  BlogCardTextContainer,
} from './BlogPostCard.style'
import { Text } from '../../generic'
import { RowSpacer } from '../../generic/Layouts'

// import {} from './BloPostCard.style'

export interface IBlogPostCardProps {
  imageSrc: string
  title: string
  description: string
  onClick?: (event: any) => void
  linkUrl?: string
  lineHeight: any
}

function BlogPostCard({ imageSrc, title, description, onClick, linkUrl }: IBlogPostCardProps) {
  return (
    <BlogCardContainer>
      <BlogCardImageContainer>
        <BlogCardImage src={imageSrc} />
      </BlogCardImageContainer>
      <BlogCardTextContainer
        style={{ cursor: 'pointer' }}
        // @ts-ignore
        onClick={
          onClick ||
          (linkUrl &&
            (() => {
              window.open(linkUrl, '_blank')
            }))
        }
      >
        <Text size={16} style={{ fontWeight: 700 }}>
          {title}
        </Text>
        <RowSpacer height="16px" style={{ flexGrow: 1, flexShrink: 1, maxHeight: '16px' }} />
        <BlogCardDescriptionText size={12} style={{ fontWeight: 400 }}>
          {description}
        </BlogCardDescriptionText>
      </BlogCardTextContainer>
    </BlogCardContainer>
  )
}

export default BlogPostCard

import React from 'react'
import { Text } from '../../generic'
import { TipItemContainer, TipImage, TipTitleContainer, TipItemActionContainer } from './TipItem.style'

function TipItem({ title, actionText, color = "primary", actionTextColor, icon, onClick }) {
  return <TipItemContainer onClick={onClick} color={color}>
    <TipImage src={icon} />
    <TipTitleContainer>
      <Text>{title}</Text>
    </TipTitleContainer>
    <TipItemActionContainer>
      <Text medium color={actionTextColor || color}>{actionText}</Text>
    </TipItemActionContainer>
  </TipItemContainer>
}

export default TipItem

import styled from '@emotion/styled'

const TipItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${props => props.theme.colors[props.color || "primary"]}20;
  border-radius: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 40px;
  width: 100%;
  align-items: center;
  padding: 8px;
`

const TipImage = styled.img`
  width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 6px;
`

const TipItemActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 4px;
`

const TipTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1
`

export {
  TipItemContainer,
  TipTitleContainer,
  TipImage,
  TipItemActionContainer
}

import React from 'react'
import { CustomModal, ModalContainer } from './GetInfoModal.style'

function GetInfoModal({ children, ...modalProps }: any) {
  return (
    <CustomModal {...modalProps}>
      <ModalContainer>{children}</ModalContainer>
    </CustomModal>
  )
}

export default GetInfoModal

import styled from '@emotion/styled'

const CompleteBlobContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
  height: 200px;
  margin: 24px;

  img {
    margin: 0;
  }
`

const BlobImage = styled.img`
  position: absolute;
  top: 0;  
  height: 200px;
`

const BlobChild = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export {
  CompleteBlobContainer,
  BlobImage,
  BlobChild
}

import styled from '@emotion/styled'

const MokuTipPanelContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${props => props.theme.colors.grey};
  border-radius: 4px;
  margin-bottom: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
`

const MokuBubble = styled.img`
  position: absolute;
  bottom: -12px;
  right: -5px;
  width: 40px;
  height: 40px;
  margin: 0;
  max-width: 40px;
`

const SmilyFaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const SmilyFace = styled.img`
  margin: 0
`

export {
  MokuTipPanelContainer,
  MokuBubble,
  SmilyFaceContainer,
  SmilyFace
}

import React from 'react'
import { CompleteBlobContainer, BlobImage, BlobChild } from './CompleteBlob.style'
import Text from '../../generic/Text'
import images from '../../../images/buckets'

function CompleteBlob({ image, title, description }) {
  return <>
    <CompleteBlobContainer>
      <BlobImage src={images.blob} alt={title} />
      <BlobChild>
        <img src={image} alt={description} />
      </BlobChild>
    </CompleteBlobContainer>
    <p />
    <Text size={18} bold>{title}</Text>
    <Text size={18} bold>{description}</Text>
  </>
  
}

export default CompleteBlob

import styled from '@emotion/styled'

// TODO: Style for Inner Shadow ...

const LoanInfoContainer = styled.div`
  background: ${props => props.theme.colors.grey};
  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
`

const Separator = styled.div`
  margin-top: 9px;
  margin-bottom: 9px;
  background: ${props => props.theme.colors.white};
  height: 3px;
`

const LoanInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7px;
  padding-left: 14px;
  padding-right: 14px;
`

const RowLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
`

const RowValue  = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export {
  LoanInfoContainer,
  LoanInfoRow,
  Separator,
  RowLabel,
  RowValue
}

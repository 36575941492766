import React, { ReactNode } from 'react'
import theme from '../../../../theme.style'
import { Text } from '../../../generic'
import { RowSpacer, ColumnSpacer, RowLayoutContainer, ColumnLayoutContainer } from '../../../generic/Layouts'
import { CustomModal, ModalContainer, MokuCustomModal, MokuIconContainer, MokuModalContainer } from './InfoModal.style'
import MokuCircleSvg from '../../../../images/svgs/mokuCircle.svg'
import CloseSvg from '../../../../images/svgs/close.svg'

interface IInfoModalProps {
  title: string | ReactNode
  description?: string | ReactNode
  buttonText?: string | ReactNode
  open: boolean
  onOpen: (isOpen: boolean) => void
}

function InfoModal({ title, description, buttonText, open, onOpen, ...modalProps }: IInfoModalProps) {
  return (
    // @ts-ignore
    <CustomModal {...modalProps} noCard open={open}>
      <ModalContainer>
        <Text style={{ fontWeight: 700, color: theme.colors.primary }} size={18}>
          {title}
        </Text>
        <RowSpacer height={20} />
        {description && (
          <Text style={{ fontWeight: 400, color: 'black', lineHeight: '24px !important' }} size={16}>
            {description}
          </Text>
        )}
        <RowSpacer height={33} />
        <Text
          clickable
          style={{ fontWeight: 700, color: theme.colors.primary }}
          size={14}
          onClick={() => onOpen(false)}
        >
          {buttonText ? buttonText : 'OK, got it'}
        </Text>
      </ModalContainer>
    </CustomModal>
  )
}

function MokuInfoModal({ title, description, open, onOpen, ...modalProps }: IInfoModalProps) {
  return (
    // @ts-ignore
    <MokuCustomModal {...modalProps} noCard open={open}>
      <MokuModalContainer>
        <RowLayoutContainer>
          <ColumnLayoutContainer>
            <RowSpacer height={8} />
            <Text style={{ fontWeight: 700, color: theme.colors.dark }} size={16}>
              {title}
            </Text>
          </ColumnLayoutContainer>
          <ColumnSpacer width={'unset'} style={{ flexGrow: 1 }} />
          <CloseSvg style={{ margin: '6px', cursor: 'pointer' }} onClick={() => onOpen(false)} />
        </RowLayoutContainer>
        <RowSpacer height={10} />
        {description && (
          <Text style={{ fontWeight: 400, color: theme.colors.dark, lineHeight: '24px !important' }} size={16}>
            {description}
          </Text>
        )}
        <RowSpacer height={4} />
        <MokuIconContainer>
          <MokuCircleSvg style={{ width: '57px', height: '57px' }} />
        </MokuIconContainer>
      </MokuModalContainer>
    </MokuCustomModal>
  )
}

export { MokuInfoModal }

export default InfoModal

import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { AuthButton, AuthContainer, LogoImageContainer, AuthButtonsContainer, MobileBackground, AgreeTermsContainer, AlreadyHaveAccountContainer, LogoImage } from './Auth.style'
import { API_URL, API_AUTH_ID } from '../../config'
import { Text, ActivityIndicator, Button } from '../generic'
import { TermsAndConditionsLink } from '../shared'
import { Col } from '../generic/grid'
import { useTranslation } from '../../hooks'
import { getAuthAud } from '../../services/auth'
import { Swiper } from '../generic'
import firebase from 'gatsby-plugin-firebase'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { largeBlob, logo } from '../../images'
import ForgottenAuth from '../ForgottenAuth'
import { prettyText } from '../../lib/utils'

const GET_CLIENT_AUTH_PROVIDER = gql`
  query($clientId: String) {
    getClientAuthProvider(clientId: $clientId)
  }
`

function AuthSwiperItem({ title, description }) {
  return  <Col centerHorizontal>
    <p />
    <Text size={24} bold>{title}</Text>
    <p />
    <Text size={16} style={{ maxWidth: "280px", textAlign: "center" }}>{description}</Text>
  </Col>
}

function Auth({ login, authProvider: givenAuthProvider, noLogo, noForgotten, redirect, azureAuth, authClientId }) {
  const { t } = useTranslation("Auth")
  const [forgottenOpen, setForgottenOpen] = useState(false)

  const clientId = typeof window !== 'undefined' && window ? new URLSearchParams(window.location.search).get('clientId') : null
  
  const { data, loading } = useQuery(GET_CLIENT_AUTH_PROVIDER, { variables: { clientId  } })

  useEffect(() => {
    firebase.analytics().logEvent("START_SIGN_IN")
  }, [])

  if (clientId && loading) {
    return <ActivityIndicator />
  }

  console.log(data)

  const authProvider = givenAuthProvider || (data && data.getClientAuthProvider)
  let goto = typeof window !== 'undefined' && window ? new URLSearchParams(window.location.search).get('redirect') : "" 
  if (!goto) {
    goto = typeof window !== 'undefined' && window ? new URLSearchParams(window.location.search).get('goto') : "" 
  }
  if (redirect) {
    goto = redirect
  }

  /*if (goto && goto === "/funeral/qq") {
    navigate('/funeral/qq')
  }*/
  if (goto && goto === "/credit-life/qq") {
    navigate('/credit-life/qq')
  }
  const authLink = provider => goto ? `${API_URL}auth/${provider}?clientId=${authClientId || API_AUTH_ID}&aud=${getAuthAud()}&redirect=${typeof window !== 'undefined' && window ? window.location.origin : ""}${clientId ? `&forClientId=${clientId}` : ""}&goto=${goto}` : `${API_URL}auth/${provider}?clientId=${authClientId || API_AUTH_ID}&aud=${getAuthAud()}${clientId ? `&forClientId=${clientId}` : ""}&redirect=${typeof window !== 'undefined' && window ? window.location.origin : ""}`

  return <AuthContainer>
    {!noForgotten && <ForgottenAuth open={forgottenOpen} setOpen={setForgottenOpen} />}
    <MobileBackground src={largeBlob} />
    {!noLogo && <>
      <LogoImageContainer>
        <LogoImage alt="Meerkat" />
      </LogoImageContainer>
    </>}
    <div style={{ width: "80%", marginBottom: "35px" }}>
    {!login && !authProvider && <Swiper showCount={1} noArrows noProgress autoPlay>
      {Array.from(Array(4).keys(), index => <AuthSwiperItem title={t(`swiperItems.${index}.title`)} description={t(`swiperItems.${index}.description`)} />)}
    </Swiper>}
    </div>
    {authProvider ? <center><Text size={13}>{`We see you are already registered with us with a ${prettyText(authProvider)} account`}</Text><p /><Text size={13} bold>Click below to sign into your profile.</Text><p/></center> : login ? <Text size={13}>{t("loginDescription")}</Text> : <Text size={13}>{t("description")}</Text> }
    <AuthButtonsContainer>
      {!azureAuth && (!authProvider || authProvider === "GOOGLE") && <AuthButton onClick={() => { firebase.analytics().logEvent("SIGN_IN_WITH_AUTH_PROVIDER", { authProvider: "GOOGLE" }); firebase.analytics().logEvent("SIGN_IN_WITH_GOOGLE");  }} href={authLink("google")} color="#DD5246"><Text size={16} white>{t("google")}</Text></AuthButton>}
      {!azureAuth && (!authProvider || authProvider === "FACEBOOK") && <AuthButton onClick={() => { firebase.analytics().logEvent("SIGN_IN_WITH_AUTH_PROVIDER", { authProvider: "FACEBOOK" }); firebase.analytics().logEvent("SIGN_IN_WITH_FACEBOOK"); }} href={authLink("facebook")} color="#3C5998"><Text size={16} white>{t("facebook")}</Text></AuthButton>}
      {(azureAuth || authProvider === "AZURE_AD") && <AuthButton onClick={() => { firebase.analytics().logEvent("SIGN_IN_WITH_AUTH_PROVIDER", { authProvider: "AZURE_AD" }); firebase.analytics().logEvent("SIGN_IN_WITH_FACEBOOK"); }} href={authLink("azure_ad")} color="#DD5246"><Text size={16} white>{t("microsoft")}</Text></AuthButton>}
    </AuthButtonsContainer>
    <p />
    {!authProvider && !noForgotten && <Button onClick={() => { firebase.analytics().logEvent("SIGN_IN_FORGOTTEN"); setForgottenOpen(true) }}>Not sure?</Button>}
    {/*!authProvider && <AlreadyHaveAccountContainer onClick={() => { if (login) { navigate('/sign-in') } else { navigate('/login') } }}>
      <Text size={13}>{t(`${login ? 'register' : 'login'}CTA`)}{"  "}<Text size={13} span bold>{t(`${login ? 'register' : 'login'}Action`)}</Text></Text>
    </AlreadyHaveAccountContainer>*/}
    <AgreeTermsContainer>
      <TermsAndConditionsLink><Text size={10}>{t('agreeTerms')}</Text></TermsAndConditionsLink>
    </AgreeTermsContainer>
  </AuthContainer>
}

export default Auth 
import happy from './happy.svg'
import happyWhite from './happyWhite.svg'
import meh from './meh.svg'
import mehWhite from './mehWhite.svg'
import sad from './sad.svg'
import sadWhite from './sadWhite.svg'
import sadRed from './sadRed.svg'

export {
  happy,
  happyWhite,
  meh,
  mehWhite,
  sad,
  sadWhite,
  sadRed
}
import React, { useEffect, useState } from 'react'
import { WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './ChangeBankAccount.style'
import { Text, ActivityIndicator } from '../../generic'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import AddBankAccount from '../../profile/AddBankAccount'

const GET_BANK_ACCOUNTS = gql`{
  myClient {
    id 
    bankAccounts {
      id
      bankName
      accountNo
    }
  }
}`

function gotoFlowComponent(FlowComponent, generateProps) {
  const component = (componentProps) => {
    const [flowOpen, setFlowOpen] = useState(true)

    return <>
      <ActivityIndicator />
      <FlowComponent open={flowOpen} setOpen={value => { setFlowOpen(value); componentProps.gotoNext() }} {...(generateProps ? generateProps(componentProps) : {})} />
    </>
  };

  return component;
}

const buildSteps = (setOpen, clientId, refetchBankAccounts, onChangeBankAccount) => ([{
  name: "bankAccount",
  title: "Select your bank account",
  fieldNames: ["payDay", "bankAccountId"],
  nextButtons: [{ label: "Add Bank Account", buttonProps: { inverted: true, outlined: false, disabled: false }, nextSteps: ["addBankAccount"] }, { label: "Confirm", nextSteps: ["done"] }],
  nextButtonsInColumn: true
}, {
  name: "addBankAccount",
  component: gotoFlowComponent(AddBankAccount, ({ gotoNext }) => ({ clientId, afterSubmit: () => { refetchBankAccounts().then(() => gotoNext()) } })),
  noNext: true,
  nextSteps: ["bankAccount"]
}, {
  name: "done",
  beforeStep: async ({ chatState }) => {
    const { payDay, bankAccountId } = chatState
    await onChangeBankAccount({ payDay, bankAccountId })
    setOpen(false)
  }
}])

const buildFields = (bankAccounts) => ({
  payDay: {
    label: "Payment Date",
    type: "select",
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  },
  bankAccountId: {
    type: "radiogroup",
    options: bankAccounts.map(b =>({ value: b.id, label: <><Text bold>{b.bankName}</Text><Text bold>Acc no. {b.accountNo}</Text></> })),
    validate: value => !value ? "Select one" : undefined
  }
})

let fields = {}

function ChangeBankAccount({ open, setOpen, bankAccountId, payDay, onChangeBankAccount }) {
  const { loading, data, error, refetch } = useQuery(GET_BANK_ACCOUNTS, { fetchPolicy: "network-only" })

  useEffect(() => {
    if (data && data.myClient) {
      if (fields.bankAccountId) {
        fields.bankAccountId.options = data.myClient.bankAccounts.map(b =>({ value: b.id, label: <><Text bold>{b.bankName}</Text><Text bold>Acc no. {b.accountNo}</Text></> }))
      } else {
        fields = buildFields(data.myClient.bankAccounts)
      }
    }
  }, [data])

  if (loading || error) { return <div /> }

  const clientId = data && data.myClient && data.myClient.id

  return <WizardModal
    name="changeBankAccount"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, clientId, refetch, onChangeBankAccount)}
    initialValues={{ bankAccountId, payDay }}
    fields={fields}
  />
}

export default ChangeBankAccount

import React, { ReactNode } from 'react'
// @ts-ignore
import { GraphQLFormProvider, FieldValue, SubmitButton } from 'react-form-helper'
import { renderInput } from '../../generic/form'
import gql from 'graphql-tag'
import { Button, Text } from '../../generic'
import { touch } from 'redux-form'
import { connect } from 'react-redux'
import { RowSpacer } from '../../generic/Layouts'
import theme from '../../../theme.style'
import { DocumentNode } from 'apollo-boost'

function getGetQuery(fields: any) {
  //deprecate
  const fieldsText = Object.keys(fields).join('\n')
  console.log('fieldsText', fieldsText)
  const GET_MY_PROFILE = gql`{
    myClient {
      id
      ${fieldsText}
    }
  }`
  return GET_MY_PROFILE
}

function getUpdateMutation(fields: any) {
  //deprecate
  const fieldsText = Object.keys(fields).join('\n')
  const UPDATE_MY_PROFILE = gql`
    mutation UpdateProfile($input: UpdateClientInput) {
      updateMyProfile(input: $input) {
        id
        ${fieldsText}
      }
    }
  `
  return UPDATE_MY_PROFILE
}

type StringMap<T> = {
  [name: string]: T
}

function customRenderInput(type: string) {
  const renderInputTp = renderInput(type)
  return ({ input, field, ...rest }: any) => {
    input.label = ''
    input.placeholder = field.placeholder
    return renderInputTp({ input, field, ...rest })
  }
}

export interface ILayoutProps {
  setTouched: (arg: string[]) => void
  children?: ReactNode
  title?: string
  fields: any //TODO: set type
}

function Layout({ setTouched, children, title, fields }: ILayoutProps) {
  console.log('children', children, fields)
  if (!children)
    return (
      <center>
        <Text bold size={16} style={{ color: theme.colors.primary }}>
          {title || ''}
        </Text>
        <RowSpacer height={21} />
        {Object.keys(fields).map((fieldName, i) => (
          <>
            {i != 0 && <RowSpacer height={14} /> /* no spec for spacing  */}
            <FieldValue name={fieldName} />
          </>
        ))}
        <RowSpacer height={38} />
        <SubmitButton
          renderSubmitButton={({ submitting, invalid, submit }: any) => (
            <Button
              title={'Update'}
              onClick={submit}
              disabled={invalid}
              onDisabledClick={() => setTouched(Object.keys(fields))}
              submitting={submitting}
            />
          )}
        />
      </center>
    )
  return (
    <>
      {children}
      <center>
        <SubmitButton
          renderSubmitButton={({ submitting, invalid, submit }: any) => (
            <Button
              title={'Update'}
              onClick={submit}
              disabled={invalid}
              onDisabledClick={() => setTouched(Object.keys(fields))}
              submitting={submitting}
            />
          )}
        />
      </center>
    </>
  )
}

const mapDispatchToProps = (dispatch: any, { formName }: any) => ({
  setTouched: (fieldNames: any) => dispatch(touch(formName, ...fieldNames)),
})

const MyLayout = connect(() => ({}), mapDispatchToProps)(Layout)

export interface IUpdateFormProps {
  formName?: string
  title?: string
  afterSubmit?: (data: any) => void
  children?: ReactNode
  fields: any //TODO: set type
  getQuery?: DocumentNode
  updateMutation?: DocumentNode
  mapInputVariables?: () => StringMap<any>
}

function UpdateForm({
  formName,
  title,
  afterSubmit,
  children,
  fields,
  getQuery,
  updateMutation,
  mapInputVariables,
  ...formProviderProps
}: IUpdateFormProps) {
  return (
    <GraphQLFormProvider
      name="profile"
      query={getQuery || getGetQuery(fields)}
      updateMutation={updateMutation || getUpdateMutation(fields)}
      mode="edit"
      fields={fields}
      renderInput={customRenderInput}
      // renderDisplay={renderDisplay}
      // renderLabel={renderLabel}
      InputFormLayout={(props: any) => (
        <MyLayout {...props} formName={formName || 'profile'} title={title} fields={fields}>
          {children}
        </MyLayout>
      )}
      afterSubmit={afterSubmit}
      initialValuesFromData={(data: any) => (data.myClient ? { ...data.myClient } : {})}
      mapInputVariables={mapInputVariables}
      {...formProviderProps}
    />
  )
}

export default UpdateForm

import React from 'react'
import BankAccountForm from '../BankAccountForm'
import { WizardModal } from '../../shared'
const { fields, CREATE_BANK_ACCOUNT } = BankAccountForm
// import {} from './AddBankAccount.style'

const steps = [{
  name: "addAccount",
  title: "Banking Details",
  fieldNames: ["branchCode", "accountNo"],
  nextSteps: ["submit"],
  nextTitle: "Confirm"
}, {
  name: "submit",
  title: "Adding Bank Account",
  submitBeforeStep: true,
  noNext: true
}]

function AddBankAccount({ open, setOpen, clientId, afterSubmit, reloadQueries, startWithCleanState }) {
  return <WizardModal 
    name="newBankAccount"
    mode="add"
    addMutation={CREATE_BANK_ACCOUNT}
    steps={steps}
    fields={fields}
    open={open}
    setOpen={setOpen}
    initialValues={{ clientId }}
    afterSubmit={data => { setOpen(false); afterSubmit && afterSubmit(data) }}
    reloadQueries={reloadQueries}
    zIndex={1002}
    startWithCleanState={startWithCleanState}
  />
}

export default AddBankAccount

import styled from '@emotion/styled'
import MokuChat from '../../../images/svgs/mokuChat.svg'
import theme, { IMeerkatWebTheme, IMeerkatWebColor } from '../../../theme.style'

interface IMokuNotePanelContainerProps {
  theme?: IMeerkatWebTheme
  color?: IMeerkatWebColor
  transparent?: boolean
  visible?: boolean
}

const MokuNotePanelContainer = styled.div<IMokuNotePanelContainerProps>`
  position: relative;
  background: ${(props) =>
    `${props.theme.colors[props.color || 'primary']}${props.color && !props.transparent ? '' : '19'}`};
  border-radius: 4px;
  // margin-bottom: 12px;
  // padding-left: 11px;
  // padding-right: 11px;
  // padding-top: 14px;
  // padding-bottom: 14px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  ${(props) => !props.visible && `display: none;`}
`

const MokuBubble = styled.img`
  width: 39.18px;
  height: 38.58px;
  margin: 0;
  max-width: 40px;
`

const CloseButton = styled.img`
  position: absolute;
  right: 11px;
  top: 8px;
  margin: 0;
  width: 16px;
  height: 16px;
`

interface IMokuChatCustomProps {
  color?: string
}

const MokuChatCustom = styled(MokuChat)<IMokuChatCustomProps>`
  width: 53px;
  height: 53px;
  ${(props) => `&>path{
    fill: ${props.color || theme.colors.primary};
  }`}
`

export { MokuNotePanelContainer, MokuBubble, CloseButton, MokuChatCustom }

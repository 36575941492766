import React from 'react'
import { Row, Col } from '../../generic/grid'
import { Text } from '../../generic'
import { MokuTipPanelContainer, MokuBubble, SmilyFaceContainer, SmilyFace } from './MokuTipPanel.style'
import { sadRed, happy, meh } from '../../../images/smily'
import { mokuChat } from '../../../images'

const getSmily = (face) => {
  if (face === "sad") { return sadRed }
  if (face === "meh") { return meh }
  return happy
}

function MokuTipPanel({ face, description }) {
  return <MokuTipPanelContainer>
    <Row>
      {face && <SmilyFaceContainer><SmilyFace src={getSmily(face)} alt={face} /></SmilyFaceContainer>}
      <Col centerVertical><Text>{description}</Text></Col>
    </Row>
    <MokuBubble src={mokuChat} />
  </MokuTipPanelContainer>
}

export default MokuTipPanel

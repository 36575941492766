import gql from 'graphql-tag'
import React from 'react'
// import {} from './BankAccountForm.style'
import { GraphQLFormProvider } from 'react-form-helper'
import { banks } from '../../../constants'
import { isNumber, isRequired } from '../../../lib/validators'
import { renderInput, renderLabel, Mandatory, ColumnLayout } from '../../generic/form'

const fields = {
  branchCode: {
    label: "Bank Name",
    type: 'select',
    options: banks,
    required: true,
    validate: [isRequired]
  },
  accountNo: {
    label: "Account Number",
    required: true,
    validate: [isRequired, isNumber]
  }
}

const GET_BANK_ACCOUNT = gql`query ($id: String!) {
  bankaccount(id: $id) {
    id
    branchCode
    accountNo
    verifyAccountInfo
  }
}`

const UPDATE_BANK_ACCOUNT = gql`mutation ($id: String!, $input: UpdateBankAccountInput!) {
  updateBankAccount(id: $id, input: $input) {
    id
    branchCode
    accountNo
    verifyAccountInfo
  }
}`

const CREATE_BANK_ACCOUNT = gql`mutation ($input: CreateBankAccountInput!) {
  createBankAccount(input: $input) {
    id
    branchCode
    accountNo
    verifyAccountInfo
  }
}`

function BankAccountForm({ id, mode, afterSubmit }) {
  return <>
    <GraphQLFormProvider
      query={GET_BANK_ACCOUNT}
      addMutation={CREATE_BANK_ACCOUNT}
      updateMutation={UPDATE_BANK_ACCOUNT}
      id={id}
      mode={mode || "edit"}
      initialValuesFromData={data => ({ ...data.bankaccount, verifyAccountInfo: true })}
      initialValues={{ verifyAccountInfo: true }}
      name={`bankAccount:${id || "NEW"}`}
      fields={fields}
      renderInput={renderInput}
      renderLabel={renderLabel}
      InputFormLayout={ColumnLayout}
      afterSubmit={afterSubmit}
    />
    <Mandatory />
    <p />
  </>
}

BankAccountForm.fields = fields
BankAccountForm.CREATE_BANK_ACCOUNT = CREATE_BANK_ACCOUNT

export default BankAccountForm

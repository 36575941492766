import React, { useState } from 'react'
import gql from 'graphql-tag'
// import {} from './GetIncomeMokuTip.style'
import { Text, Button } from '../../generic'
import { isRequired, isNumber } from '../../../lib/validators'
import { MokuNotePanel, GetInfoModal } from '../../shared'
import { RowSpacer } from '../../generic/Layouts'
import UpdateForm from '../../shared/GetInfoModal/UpdateForm'

const GET_INCOME = gql`
  {
    myClient {
      id
      income
      estimatedIncome
    }
  }
`

const UPDATE_INCOME = gql`
  mutation UpdateProfile($input: UpdateClientInput) {
    updateMyProfile(input: $input) {
      id
      income
    }
  }
`

const updateIncomeFields = {
  income: {
    label: 'Income',
    type: 'text',
    validate: [isRequired, isNumber],
    placeholder: 'Enter your Monthly Income',
  },
}

export interface IGetIncomeMokuTipProps {
  income: number
  estimatedIncome: number
}

function GetIncomeMokuTip({ income, estimatedIncome }: IGetIncomeMokuTipProps) {
  const [getIncomeOpen, setGetIncomeOpen] = useState(false)
  const [incomeState, setIncomeState] = useState(income || 0)
  console.log('income?', incomeState, income)

  return (
    <>
      <GetInfoModal open={getIncomeOpen}>
        <UpdateForm
          fields={updateIncomeFields}
          afterSubmit={(data) => {
            console.log('after submit:', data.data)
            setGetIncomeOpen(false)
            if (data.data.updateMyProfile.income != null) {
              console.log('income state?:', data.data.updateMyProfile.income)
              setIncomeState(data.data.updateMyProfile.income)
            }
          }}
          getQuery={GET_INCOME}
          updateMutation={UPDATE_INCOME}
          title={'My Income'}
          // @ts-ignore
          initialValuesFromData={() => ({ income: estimatedIncome })}
          keepInitialValues
        />
      </GetInfoModal>
      {!incomeState && incomeState == 0 && (
        <MokuNotePanel
          title={'Moku Tip: Provide your Monthly Income'}
          description={
            <div style={{ paddingRight: '28px' }}>
              <RowSpacer height={8} />
              <Text size={13} span style={{ fontWeight: 400, lineHeight: '20px !important' }}>
                {estimatedIncome && estimatedIncome != 0 && 'We have used an estimation of your income. '}
                Provide your monthly income to better manage your finances and help us provide you with a better
                experience
              </Text>
              <RowSpacer height={22} />
            </div>
          }
          footer={
            <>
              <center style={{ width: '100%' }}>
                <Button inverted onClick={() => setGetIncomeOpen(true)}>
                  Add Income
                </Button>
              </center>
              <RowSpacer height={8} />
            </>
          }
        />
      )}
    </>
  )
}

export default GetIncomeMokuTip

import styled from '@emotion/styled'

const MyDashboardContainer = styled.div`
  margin-bottom: 150px;
`

const SummaryItemsRow = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const DashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 3px;
  margin-right: 3px; 
  flex: 1;
  padding: 8px;
`

const TitleTextContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 7px;
`

const DashboardCardContentsContainer = styled.div`
  height: 100px;
  margin: 8px;
`

const OpenOptionContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grey6};
  margin-left: 6px;
  margin-right: 6px;
  padding: 14px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: ${props => props.theme.colors.grey};
`

const debtColors = {
  home: "primary",
  education: "alertAmber",
  personal: "red",
  car: "yellow",
  credit: "purple"
}

const ToggleProductOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export {
  MyDashboardContainer,
  SummaryItemsRow,
  DashboardCard,
  TitleTextContainer,
  DashboardCardContentsContainer,
  OpenOptionContainer,
  debtColors,
  ToggleProductOptionsRow
}
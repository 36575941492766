import React, { useState, ReactNode } from 'react'
import InfoModal from '../InfoModal'
import { MokuInfoModal } from '../InfoModal/InfoModal'
import { CustomInfoIconSvg, IconContainer } from './InfoIcon.style'
// import {} from './InfoModal.style'

interface IInfoIconProps {
  color?: string
  onClick?: () => void
  clickable?: boolean
  lineOffset?: number
}

function InfoIcon({ color, onClick, clickable, lineOffset }: IInfoIconProps) {
  return (
    <IconContainer clickable={clickable} lineOffset={lineOffset}>
      <CustomInfoIconSvg color={color} onClick={onClick} />
    </IconContainer>
  )
}

interface IInfoIconWithModalProps {
  color?: string
  title: string | ReactNode
  description?: string | ReactNode
  buttonText?: string | ReactNode
  useMokuModal?: boolean
  lineOffset?: number
}

function InfoIconWithModal({
  title,
  description,
  buttonText,
  useMokuModal,
  color,
  lineOffset,
  ...infoIconProps
}: IInfoIconWithModalProps) {
  const [openModal, onOpenModal] = useState(false)

  const ModalComponent = useMokuModal ? MokuInfoModal : InfoModal

  return (
    <>
      <ModalComponent
        title={title}
        description={description}
        buttonText={buttonText}
        open={openModal}
        onOpen={onOpenModal}
      />
      <InfoIcon {...infoIconProps} lineOffset={lineOffset} color={color} clickable onClick={() => onOpenModal(true)} />
    </>
  )
}

export { InfoIconWithModal, IInfoIconWithModalProps, IInfoIconProps }

export default InfoIcon

import styled from '@emotion/styled'
import InfoIconSvg from '../../../../images/svgs/infoIcon.svg'

interface ICustomInfoIconSvgProps {
  color?: string
}

const CustomInfoIconSvg = styled(InfoIconSvg)<ICustomInfoIconSvgProps>`
  ${(props) =>
    `&>* {
      stroke: ${props.color ? props.color : 'black'};
    }`}
`

interface IIconContainerProps {
  clickable?: boolean
  lineOffset?: number
}

const IconContainer = styled.div<IIconContainerProps>`
  display: inline-block;
  position: relative;
  padding: 0 10px;
  flex-direction: column;
  ${(props) => props.clickable && `cursor: pointer;`}
  ${(props) => `bottom: ${props.lineOffset ? props.lineOffset - 2 : `-2`}px;`}
`

export { CustomInfoIconSvg, IconContainer }

import React from 'react'
import { getAffordabilitySummary, getRiskProfile } from '../../../lib/financial'
//TODO: don't use this
import {
  Card,
  CardRow,
  GraphContainer,
  CardInfoIconContainer,
} from '../../financialHealth/MyFinancialHealth/MyFinancialHealth.style'
import { PieGraph, Text } from '../../generic'
import { ColumnSpacer, RowSpacer } from '../../generic/Layouts'
import { MokuNotePanel } from '..'
import { IMeerkatWebColors } from '../../../theme.style'
import { InfoIconWithModal } from '../infoModal/InfoIcon'

const getMokuTipProps = ({
  affordability,
  compuScore,
}: {
  affordability: number
  compuScore: number
}): { title: string; description: string; color: keyof IMeerkatWebColors } | undefined => {
  if (compuScore > 620 && affordability > 0) {
    return {
      title: 'Moku Tip: Credit Worthiness is Looking Good!',
      description: 'Your credit score and ability to keep up with your credit commitments are both pretty good!',
      color: 'primary',
    }
  }
  if (compuScore > 620 && affordability <= 0) {
    return {
      title: 'Moku Tip: Let’s relook your budget!',
      description:
        'Your credit score is pretty good Well done. However, your debt capacity to pay your debts is low.  We recommend you review your budget to see if you can reduce your current expenses.',
      color: 'alertAmber',
    }
  }
  if (compuScore <= 620 && affordability > 0) {
    return {
      title: 'Moku Tip: Let’s work on your profile',
      description: ' Your credit rating is low, but your debt capacity is high.',
      color: 'alertAmber',
    }
  }
  if (compuScore <= 620 && affordability <= 0) {
    return {
      title: 'Moku Tip: Let’s improve your profile!',
      description:
        'Your profile is not looking promising you might struggle to get credit. We can help improve this through our debt management solution.',
      color: 'alertRed',
    }
  }
}

interface ICreditRecord {
  affordability: number
  compuScore: number
}

export interface ICreditWorthinessFactorsProps {
  creditRecord?: ICreditRecord
  income?: number
}

function CreditWorthinessFactors({ creditRecord, income }: ICreditWorthinessFactorsProps) {
  if (!creditRecord || !income) {
    return null
  }

  const riskSummary = getRiskProfile(creditRecord)
  const affordabilitySummary = getAffordabilitySummary(creditRecord.affordability, income)
  const { affordability, compuScore } = creditRecord
  const mokuTipProps = getMokuTipProps({ affordability, compuScore })

  console.log('affordability, compuScore', affordability, compuScore, mokuTipProps)

  if (!mokuTipProps) {
    return null
  }

  return (
    <>
      <Text size={16} bold>
        Credit Worthiness Factors
      </Text>
      <RowSpacer height={16} />
      <MokuNotePanel {...mokuTipProps} transparent visible />
      <RowSpacer height={8} />
      <CardRow>
        <Card style={{ margin: '0px' }}>
          <CardInfoIconContainer>
            <InfoIconWithModal
              title="What is My Credit Score?"
              description="Your credit score is an indicator of your creditworthiness, or your ability to repay debt. The score is based on your repayment history and credit files held by all the various credit providers"
              buttonText="OK, got it"
            />
          </CardInfoIconContainer>
          <Text bold small>
            Credit Score
          </Text>
          <GraphContainer small>
            <PieGraph min={-350} max={750} {...riskSummary} />
          </GraphContainer>
        </Card>
        <ColumnSpacer width={8} />
        <Card style={{ margin: '0px' }}>
          <CardInfoIconContainer>
            <InfoIconWithModal
              title="What is My Debt Capacity"
              description="Your debt capacity is your financial capacity to fund your existing and new debt."
              buttonText="OK, got it"
            />
          </CardInfoIconContainer>
          <Text bold small>
            My Debt Capacity
          </Text>
          <GraphContainer small>
            <PieGraph
              value={creditRecord.affordability}
              min={Math.min(creditRecord.affordability, -1000)}
              max={Math.max(creditRecord.affordability, income * 0.4)}
              {...affordabilitySummary}
            />
          </GraphContainer>
        </Card>
      </CardRow>
    </>
  )
}

export default CreditWorthinessFactors

import styled from '@emotion/styled'

const InfoCardContainer = styled.div`
  background: ${props => props.theme.colors.grey4}18;
  border-radius: 4px;
  width: 100%;
  max-width: 330px;
  padding-top: 13px;
  padding: 10px;
  margin: 5px;
`

export {
  InfoCardContainer
}

import styled from '@emotion/styled'

const TermsAndConditionsContainer = styled.div`
  width: 100wh;
  height: 100vh;
  margin: 8px;
  overflow: scroll;
  padding-top: 24px;
  text-align: left;
`

export {
  TermsAndConditionsContainer
}

import React from 'react'
import { TermsAndConditionsContainer } from './TermsAndConditionsModal.style'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import Modal from '../../generic/Modal'
import Text from '../../generic/Text'
import ActivityIndicator from '../../generic/ActivityIndicator'
import { closeCircle } from '../../../images'
import { CloseButton } from '../Wizard/Wizard.style'

const GET_TERMS_AND_CONDITIONS = gql`{
  getTemplate(name: "CONST_TERMS")
}`

function TermsAndConditionsModal({ open, setOpen }) {
  const { data, loading, error } = useQuery(GET_TERMS_AND_CONDITIONS)

  return <Modal open={open} setOpen={setOpen}>
    <TermsAndConditionsContainer>
      <CloseButton src={closeCircle} alt="X" onClick={() => setOpen(false)} />
      <center><Text bold size={26}>Terms and Conditions</Text></center>
      <p />
      {loading || error 
        ? loading ? <ActivityIndicator /> : error 
        : <div dangerouslySetInnerHTML={{__html: data.getTemplate  }} />
      }
    </TermsAndConditionsContainer>
  </Modal>
}

export default TermsAndConditionsModal

import styled from '@emotion/styled'

const SendDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: min(92%, 330px);
  align-items: center;
  
`

export {
  SendDocumentContainer
}

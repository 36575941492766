import React from 'react'
import WizardModal from '../../shared/WizardModal'
import InformationRow from '../../shared/InformationRow'
import { FormField, FieldValue, FormRenderProvider, FieldValues } from 'react-form-helper'
import gql from 'graphql-tag'
import Text from '../../generic/Text'
import { Row } from '../../generic/grid'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { isRequired } from '../../../lib/validators'
import moment from 'moment'
import { printCurrency } from '../../../lib/utils'

const GET_DEBIT_ORDER_MANDATE = gql`query ($id: String!) {
  debitorder(id: $id) {
    paymentType
    maxAmount
    bankAccount {
      accountNo
      bankName
      accountHolderName
    }
    payDay
    nextDebitDate
    associatedProducts {
      amount
      description
    }
    debitOrderMandateId
    debitOrderMandateInvalid
    mandate {
      authenticationType
      status
    }
    alreadySigned
  }
}`

const SIGN_DEBIT_ORDER_MANDATE = gql`mutation ($id: String!, $signature: SignatureInput) {
  updateDebitOrder(id: $id, input: { signature: $signature }) {
    id
  }
}`

const renderField = ({ mode, Value, Label }) => {
  if (mode === "display") {
    return <Value mode="display" renderDisplay={_ => {
      const renderer = ({ value, input }) => <Text span bold>{value || input && input.value}</Text>;

      return renderer;
    }}/>
  }
  return <Row padHorizontal="8px">
    <InformationRow title={<Label />} value={<Value mode="display" />} />
  </Row>
}

/*function SigningDebitOrderInfo() {
  return <>
    <FormRenderProvider renderField={renderField}>
      <Row padBottom="18px">
        <Text size={14}>I, <FormField mode="display" name="bankAccount.accountHolderName" />, hereby authorise <Text span bold>MyMeerkat FSP (PTY) Ltd of 203, Pine Park, 1 Logan Way, Pinelands, 7405</Text>, to issue and deliver payment instructions to my bank for collection of insurance premiums against my bank account, being:</Text>
      </Row>
      <LoanInfoContainer>
        <FormField name="bankAccount.accountHolderName"  />
        <FormField name="bankAccount.bankName" />
        <FormField name="bankAccount.accountNo" />
        <FieldValue name="associatedProducts" renderInput={_ => ({ value, input, field }) => <Row padHorizontal="8px"><InformationRow title={field.label} value={(value || input.value).map(d => d.description).join(', ')} /></Row>} />
        <Separator />
        <FormField name="nextDebitDate" />
      </LoanInfoContainer>
      <FieldValues names={["paymentType", "mandate.authenticationType"]} renderValues={({ values }) => { console.log(values); return <div /> }} />
    </FormRenderProvider>
    <Row padTop="18px">
      <Text size={14}>Subsequent payment instructions will continue in terms of this authority unles cancelled by written notice by myself or MyMeerkat FSP (PTY) Ltd.</Text>
    </Row>
  </>
}*/

function DebitOrderInfo() {
  return <>
    <FormRenderProvider renderField={renderField} renderLabel={_ => ({ field }) => field.label}>
      <LoanInfoContainer>
        <FormField name="bankAccount.accountHolderName"  />
        <FormField name="bankAccount.bankName" />
        <FormField name="bankAccount.accountNo" />
        <FieldValue name="associatedProducts" renderInput={_ => {
          const renderer = ({ value, input, field }) => <Row padHorizontal="8px"><InformationRow title={field.label} value={(value || input.value).map(d => d.description).join(', ')} /></Row>;
          
          return renderer;
        }} />
        <FieldValue name="associatedProducts" renderInput={_ => {
          const renderer = ({ value, input }) => <Row padHorizontal="8px"><InformationRow title={"Amount"} value={(printCurrency((value || input.value).reduce((total, d) => total + d.amount, 0)))} /></Row>;
          
          return renderer;
        }} />
        <Separator />
        <FormField name="nextDebitDate" />
      </LoanInfoContainer>
      <FieldValues names={["paymentType", "mandate.authenticationType"]} renderValues={({ values }) => { console.log(values); return <Text size={14} padTop="16px">You will receive a message from your bank to accept a Debicheck mandate. Please accept the mandate to ensure payment can be collected and you are covered. Should you not receive any message from your bank within 3 days please contact us on 021 003 4534 to get you covered.</Text> }} />
    </FormRenderProvider>
    <Row padTop="16px">
      <Text size={14}>Subsequent payment instructions will continue in terms of this authority unless cancelled by written notice by myself or MyMeerkat FSP (PTY) Ltd.</Text>
    </Row>
  </>
}

const GetFirstStep = ({ gotoNext }) => {
  return <FieldValues names={["paymentType", "mandate", "debitOrderMandateId", "debitOrderMandateInvalid"]} renderValues={({ values }) => { 

    if (values.alreadySigned) {
      gotoNext(["signed"]) 
    } else 
    if (values.paymentType === "DEBI_CHECK") { 
      gotoNext(["debiCheck"])
    } else {
      gotoNext(["confirm2"]) 
    }
    return <div />
  }} />
}

const buildSteps = (setOpen) => ([{
  name: "step1",
  component: GetFirstStep,
  noNext: true
}, {
  name: "signed",
  title: "Debit Order Mandate",
  title2: "You are all set",
  title3: "Thank you for signing the Debit Order Mandate",
  nextButtons: [{ label: "View Policy Document(s)", onClick: () =>  { window.location = 'https://my.meerkat.co.za/documents' } }, { label: "Done", nextSteps: ["done"] }],
  nextButtonsInColumn: true
}, {
  name: "debiCheck",
  title: "Debit Order Mandate",
  title2: "We have sent you a Debicheck request",
  title3: "You will receive a notification request from your bank. Please accept the request and then we will be able to start debiting your account",
  nextTitle: "Done",
  nextSteps: ["done"]
}, {
  name: "confirm2",
  title: () => <FieldValue name="paymentType" renderInput={_ => {
    const renderer = ({ input, value }) => <Text>{(value || input.value) === "DEBI_CHECK" ? "Debi Check Mandate" : "Debit Order Mandate"}</Text>;

    return renderer;
  }}/>,
  title2: "Confirmation",
  component: DebitOrderInfo,
  nextSteps: ["done"],
  nextTitle: "OK"
}, {
  name: "confirm",
  title: "Debit Order Mandate",
  title2: "We need confirmation on your information",
  component: DebitOrderInfo,
  nextSteps: ["sign"],
  nextTitle: "Confirm"
}, {
  name: "sign",
  title: "Debit Order Mandate",
  title2: "Add your signature to make it official",
  title3: <Text size={14} alignLeft>I recognise and accept that the Debit Order and/or NAEDO instructions will emanate from MyMeerkat FSP (PTY) Ltd, giving them the authority to collect the monthly insurance premiums from my account.</Text>,
  fieldNames: ["signature"],
  component: () => <Row pad="14px" padTop="24px"><Text size="14">I accept liability for any bank charges or penalties incurred in the event of unpaid collections for whatever reason.</Text></Row>,
  nextSteps: ["submit"],
  nextTitle: "Submit"
}, {
  name: "submit",
  submitBeforeStep: true,
  nextAfterDelay: 1000,
  noNext: true,
  nextSteps: ["signed"]
}, {
  name: "done",
  beforeStep: () => setOpen(false)
}])

const fields = {
  "bankAccount.accountHolderName": {
    type: "text",
    label: "Account Holder Name"
  },
  "bankAccount.bankName": {
    type: "text",
    label: "Bank"
  },
  "bankAccount.accountNo": {
    type: "text",
    label: "Account Number"
  },
  "associatedProducts": {
    type: "text",
    label: "Statement Reference"
  },
  "nextDebitDate": {
    type: "date",
    label: "First Payment Instruction"
  },
  "signature": {
    type: "signature",
    validate: [isRequired]
  },
  "amount": {
    type: "currency",
    label: "Payment Amount"
  }
}

function DebitOrderMandate({ id, open, setOpen, refetchQueries, ...props }) {
  return <WizardModal
    id={id}
    mode="edit"
    name="signDoMandate"
    query={GET_DEBIT_ORDER_MANDATE}
    updateMutation={SIGN_DEBIT_ORDER_MANDATE}
    initialValuesFromData={data => ({ ...data.debitorder, signature: null })}
    mapInputVariables={({ signature }) => ({ signature: { type: "WEB_SIGNATURE", signature, timestamp: moment().valueOf() } })}
    afterSubmit={() => setOpen && setOpen(false)}
    open={open}
    setOpen={setOpen}
    keepInitialValues
    steps={buildSteps(setOpen)}
    fields={fields}
    refetchQueries={refetchQueries}
    {...props}
  />
}

export default DebitOrderMandate

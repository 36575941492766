import React, { useState } from 'react'
import TermsAndConditionsModal from '../TermsAndConditionsModal'

function TermsAndConditionsLink({ children }) {
  const [open, setOpen] = useState(false)

  return <>
    <TermsAndConditionsModal open={open} setOpen={setOpen} />
    <span onClick={() => setOpen(true)}>{children}</span>
  </>
}

export default TermsAndConditionsLink

import React, { ReactNode, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Text } from '../../generic'

const BlogCardImage = styled.img`
  width: 100%;
  object-fit: cover;
  min-height: 100%;
`

const BlogCardImageContainer = styled.div`
  height: 50%;
  display: flex;
`

const BlogCardContainer = styled.div`
  height: 292px;
  background: #ffffff;
  max-width: 160px;
  margin: 4px;
  /* Card_1 */
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 2px;
`

const BlogCardTextContainer = styled.div`
  height: 50%;
  padding 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`

const BlogCardsContainerOuter = styled.div``

const BlogCardsContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px -4px;
`

interface IBlogCardsContainerProps {
  children: ReactNode
}

const BlogCardsContainer = ({ children }: IBlogCardsContainerProps) => {
  return (
    <BlogCardsContainerOuter>
      <BlogCardsContainerInner>{children}</BlogCardsContainerInner>
    </BlogCardsContainerOuter>
  )
}

const BlogCardDescriptionTextInner = styled.p`
  margin: 0;
  padding: 0;
`

const DescriptionTextContainer = styled(Text)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

interface IBlogCardDescriptionTextProps {
  children: ReactNode
  lineHeight?: number
  size: number
  style: any
}

const BlogCardDescriptionText = ({ lineHeight = 12, children, ...textProps }: IBlogCardDescriptionTextProps) => {
  const pContent = useRef(null)

  useEffect(() => {
    // Round height to line-height
    if (pContent.current) {
      // @ts-ignore
      const element: HTMLElement = pContent.current as HTMLElement
      const rect: any = element.getBoundingClientRect()
      const lineCount = rect && Math.floor(rect.height / lineHeight)
      element.style.height = lineCount * lineHeight + 'px'
    }
  }, [pContent.current])

  return (
    <DescriptionTextContainer size={12}>
      <BlogCardDescriptionTextInner ref={pContent} {...textProps} style={{ overflow: 'hidden' }}>
        {children}
      </BlogCardDescriptionTextInner>
      <BlogCardDescriptionTextInner {...textProps}>{'…'}</BlogCardDescriptionTextInner>
    </DescriptionTextContainer>
  )
}

export {
  BlogCardImage,
  BlogCardImageContainer,
  BlogCardContainer,
  BlogCardTextContainer,
  BlogCardsContainer,
  BlogCardDescriptionText,
}

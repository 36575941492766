import styled from '@emotion/styled'
import { device } from '../../theme.style'

const InfoBlockContentsContainer = styled.div`
  max-width: 381px;
  min-width: 250px;
  margin: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InfoBlockContainer = styled.div`
  width: 19.8%;
  min-width: max(250px, 30%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InfoBlockImageContainer = styled.div`
  margin-bottom: 28px;
  width: ${props => props.imageWidth || "100%"};
  
  ${device.tablet`
    width: 76px;
    height: 65px;
  `}
`

export {
  InfoBlockContainer,
  InfoBlockContentsContainer,
  InfoBlockImageContainer
}

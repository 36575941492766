import React from 'react'
import mokuPopup from '../../../images/mokuPopup.svg'
import { MokuPopup, ConfirmModalContainer,ConfirmModalContentsContainer, StyledModal, ConfirmButtonsContainer } from './ConfirmModal.style'
import Text from '../../generic/Text'
import Button from '../../generic/Button'

function ConfirmModal({ open, title, description, confirmText, cancelText, onConfirm, onCancel }) {
  return <StyledModal open={open} noCard>
    <ConfirmModalContainer>
    <MokuPopup src={mokuPopup} alt="Moku" />
    <ConfirmModalContentsContainer>
      <Text heading bold>{title}</Text>
      <Text><p>{description}</p></Text>
      <ConfirmButtonsContainer>
        {cancelText && <Button inverted title={cancelText} onClick={onCancel} />}
        <Button title={confirmText} onClick={onConfirm} />
      </ConfirmButtonsContainer>
    </ConfirmModalContentsContainer>
    </ConfirmModalContainer>
  </ StyledModal>
}

export default ConfirmModal

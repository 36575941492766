import React from 'react'
// import {} from './TimelineItem.style'
import { Row, Col } from '../../generic/grid'
import Text from '../../generic/Text'

function TimelineItem({ title, date, current, description }) {
  return <>
    <Col padBottom="16px">
      <Row>
        <Col size={0} padRight="8px" padBottom="8px"><Text color={current && "primary"} size="16" bold>{title}</Text></Col>
        <Col alignVertical='flex-end' padBottom="10px"><Text size="10" color="grey3">{date}</Text></Col>
      </Row>
      <Row>
        <Text size="13" color="grey3">{description}</Text>
      </Row>
    </Col>
  </>
}

export default TimelineItem

import styled from '@emotion/styled'
import Modal from '../../generic/Modal'

const CustomModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.12);
  // display: flex;
  align-items: baseline;
  // justify-content: center;
  padding-top: 145px;
  // opacity: 1;
`

//TODO: add color prop
const OverlayDiv = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 145px;
`

//TODO: add color prop
const ModalContainer = styled.div`
  width: 330px;
  // height: 226px;
  background: #f9f8f8;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
  border-radius: 4px;
  padding: 26px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export { OverlayDiv, ModalContainer, CustomModal }

import styled from '@emotion/styled'

const AnnouncementModalContainer = styled.div`
  background-color: white;
  position: relative;
  width: 300px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 532px;
  height: 100vh;
  overflow: hidden;
`

const AnnouncementModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 58px;
  padding-bottom: 35px;
  z-index: 2;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
`

const AnnouncementCard = styled.div`
  margin-top: -9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 240px;
  padding: 15px;
  padding-top: 35px;
  padding-bottom: 28px;
  background-color: ${props => `${props.theme.colors.white}66`};
  border-radius: 8px;
`

const CloseButton = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  z-index: 3;
`

const AnnouncementModalActionContainer = styled.div`
  position: absolute;
  bottom: 10px
`

const BlobBackground = styled.img`
  position: absolute;
  top: -50px;
  width: 310px;
`

const MeerkatLogo = styled.img`
  margin: 0;
  height: 45px;
  margin-bottom: 17px;
`

const AnnouncementImage = styled.img`
  margin: 0;
  height: 132px;
  margin-bottom: 38px;
`

export {
  AnnouncementModalContainer,
  AnnouncementModalContentContainer,
  AnnouncementCard,
  AnnouncementModalActionContainer,
  CloseButton,
  BlobBackground,
  MeerkatLogo,
  AnnouncementImage
}

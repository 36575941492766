import styled from '@emotion/styled'

const MokuHoldingCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 0
  }
`

const MokuPopup = styled.div`
  width: 112px;
  z-index: 2;
`

const CardContainer = styled.div`
  margin: -14px;
  background: ${props => props.theme.colors.primary};
  border-radius: 8px;
  padding: 14px;
  min-width: 120px;
`

export {
  MokuHoldingCardContainer,
  MokuPopup,
  CardContainer
}

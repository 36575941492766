import styled from '@emotion/styled'
import { MobileBackground } from '../profile/RegisterProfile/RegisterProfile.style'

const AuthButton = styled.a`
  margin-bottom: 14px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  margin-right: 32px;
  margin-left: 32px;
  height: 48px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 32px;
  text-decoration: none;
`

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const LogoImageContainer = styled.div`
  margin-top: 66px;
  margin-bottom: 55px;
  width: 127px;
`

const AuthButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`

const AgreeTermsContainer = styled.div`
  position: absolute;
  bottom: 31px;
  margin-left: auto;
  margin-right: auto;
  width: 197px;
  text-align: center;
`

const AlreadyHaveAccountContainer = styled.div`
  position: absolute;
  bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  width: 287px;
  text-align: center;
`

const LogoImage = styled.img`
  content: url(${props => props.theme.logo});
`

export {
  AuthButton,
  AuthContainer,
  LogoImageContainer,
  AuthButtonsContainer,
  MobileBackground,
  AgreeTermsContainer,
  AlreadyHaveAccountContainer,
  LogoImage
}
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { debtColors } from '../../dashboard/MyDashboard/MyDashboard.style'
import { IMeerkatWebTheme } from '../../../theme.style'

const MyFinancialHealthContainer = styled.div`
  margin-bottom: 75px;
`

const CardGrid = styled.div`
  margin-bottom: 28px;
`

const CardRow = styled.div<{ fill?: boolean }, IMeerkatWebTheme>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  ${(props) =>
    props.fill &&
    css`
      background-color: ${props.theme.colors.grey};
    `}
`

const GraphContainer = styled.div<{ small?: boolean }>`
  max-width: ${(props) => (props.small ? '100px' : '200px')};
  width: 66%;
  margin-top: 9px;
  margin-bottom: 11px;
`

const Card = styled.div<{ blank?: boolean }>`
  position: relative;
  max-width: max(33%, 160px);
  min-width: max(28%, 140px);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 4px;
  ${(props) => !props.blank && `box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08)`};
  border-radius: 2px;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
`

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  heigth: 100%;
  align-items: center;
  justify-content: center;

  img {
    margin: 0;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 8px;
`

const CardInfoIconContainer = styled.div`
  position: absolute;
  right: -4px;
  top: 4px;
`

export {
  MyFinancialHealthContainer,
  CardGrid,
  CardRow,
  Card,
  GraphContainer,
  ArrowContainer,
  TitleContainer,
  debtColors,
  CardInfoIconContainer,
}

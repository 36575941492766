import React from 'react'
import { Circle, TimelineLine } from './Timeline.style'
import { Row, Col } from '../../generic/grid'
import TimelineItem from '../TimelineItem'

function Timeline({ items, currentIndex }) {
  return <Col>
    {
      items.map((item, index) => <Row key={index}>
        <Col size="0" alignHorizontal='center' padRight="8px">
          <TimelineLine hidden={index === 0} height={index === currentIndex ? "0px" : "3px"} color={index < currentIndex ? "primary" : "grey5"} />
          <Circle small={index !== currentIndex} color={index <= currentIndex ? "primary" : "grey5"} />
          <TimelineLine hidden={index === items.length - 1} color={index < currentIndex ? "primary" : "grey5"} />
        </Col>
        <TimelineItem {...item} current={index === currentIndex} />
      </Row>)
    }
  </Col>
}

export default Timeline
